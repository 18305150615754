import { getUnixTime, fromUnixTime, format, addDays } from "date-fns";
import sort from "ramda/src/sort";

const diff = function (a, b) {
  return a - b;
};

const trendsOverTimeReport = ({ dates }) => {
  console.log("TRENDS OVER TIME", dates);

  const labels = Object.keys(dates).map((date) => {
    const d = addDays(new Date(date), 1);

    return getUnixTime(d);
  });

  let sortedLabels = sort(diff, labels);

  // only show the last 7 days worth of entries
  sortedLabels = sortedLabels.slice(Math.max(sortedLabels.length - 7, 0));

  const GREAT = sortedLabels.map((dateLabel) => {
    const dateKey = format(fromUnixTime(dateLabel), "yyyy-MM-dd");
    const date = dates[dateKey];

    console.log("GREAT DATE", date, dateKey);

    if (!date) {
      return 0;
    }

    if (date.hasOwnProperty("1")) {
      return date["1"];
    }

    return 0;
  });

  const GOOD = sortedLabels.map((dateLabel) => {
    const dateKey = format(fromUnixTime(dateLabel), "yyyy-MM-dd");
    const date = dates[dateKey];

    console.log("GOOD DATE", date, dateKey);

    if (!date) {
      return 0;
    }

    if (date.hasOwnProperty("2")) {
      return date["2"];
    }

    return 0;
  });

  const MEH = sortedLabels.map((dateLabel) => {
    const dateKey = format(fromUnixTime(dateLabel), "yyyy-MM-dd");
    const date = dates[dateKey];

    console.log("MEH DATE", date, dateKey);

    if (!date) {
      return 0;
    }

    if (date.hasOwnProperty("3")) {
      return date["3"];
    }

    return 0;
  });

  const NOT_GOOD = sortedLabels.map((dateLabel) => {
    const dateKey = format(fromUnixTime(dateLabel), "yyyy-MM-dd");
    const date = dates[dateKey];

    console.log("NOT GOOD DATE", date, dateKey);

    if (!date) {
      return 0;
    }

    if (date.hasOwnProperty("4")) {
      return date["4"];
    }

    return 0;
  });

  const ANGRY = sortedLabels.map((dateLabel) => {
    const dateKey = format(fromUnixTime(dateLabel), "yyyy-MM-dd");
    const date = dates[dateKey];

    console.log("ANGRY DATE", date, dateKey);

    if (!date) {
      return 0;
    }

    if (date.hasOwnProperty("5")) {
      return date["5"];
    }

    return 0;
  });

  const displayLabels = sortedLabels.map((label) => {
    const dateLabel = format(fromUnixTime(label), "yyyy-MM-dd");
    return dateLabel;
  });

  const lineChartData = {
    labels: [...displayLabels],
    datasets: [
      {
        label: "🤗 Great!",
        data: [...GREAT],
        fill: false,
        backgroundColor: "#20bf6b",
        borderColor: "rgba(32, 191, 107, 0.4)",
      },
      {
        label: "🙂 Good",
        data: [...GOOD],
        fill: false,
        backgroundColor: "#45aaf2",
        borderColor: "rgba(69, 170, 242, 0.4)",
      },
      {
        label: "😐 Neutral",
        data: [...MEH],
        fill: false,
        backgroundColor: "#a5b1c2",
        borderColor: "rgba(165, 177, 194,0.4)",
      },
      {
        label: "😒 Not good",
        data: [...NOT_GOOD],
        fill: false,
        backgroundColor: "#f7b731",
        borderColor: "rgba(247, 183, 49, 0.4)",
      },
      {
        label: "🤬 Angry",
        data: [...ANGRY],
        fill: false,
        backgroundColor: "#fc5c65",
        borderColor: "rgba(252, 92, 101, 0.4)",
      },
    ],
  };

  return lineChartData;
};

export default trendsOverTimeReport;
