import * as React from 'react'

import {db} from '../../api/db-config'
import CsvDownload from 'react-json-to-csv'

import createUUID from '../../utils/uuid'

import format from 'date-fns/format'

import {
  AppProvider,
  Page,
  TextField,
  Layout,
  Card,
  Button,
  FormLayout,
  DisplayText,
  DataTable,
  Badge,
} from '@shopify/polaris'

const Admin = () => {
  const [companies, setCompanies] = React.useState([])
  const [leads, setLeads] = React.useState([])

  const fetchLeads = () => {
    db.collection('leads')
      .get()
      .then(snapshot => {
        let newLeads = []

        snapshot.forEach(doc => {
          const lead = doc.data()
          newLeads.push(lead.email)
        })

        setLeads([...newLeads])
      })
  }

  const fetchNewCompanies = () => {
    db.collection('new-companies')
      .get()
      .then(querySnapshot => {
        let newCompanies = []

        querySnapshot.forEach(doc => {
          const company = doc.data()
          newCompanies.push(company)
        })

        newCompanies.sort(function (a, b) {
          return a.invoice_sent - b.invoice_sent
        })

        setCompanies([...newCompanies])
      })
  }

  React.useEffect(() => {
    // fetch new companies that need a subscription setup
    fetchNewCompanies()
    fetchLeads()
  }, [])

  const handleMarkAsSent = companyId => {
    db.collection('new-companies')
      .doc(companyId)
      .update({
        invoice_sent: true,
        invoice_sent_on: Date.now(),
      })
      .then(() => {
        fetchNewCompanies()
      })
  }

  const customerRows = companies.map(company => [
    company.company_name,
    company.plan,
    company.app_sumo_code,
    company.company_admin,
    company.company_email,
    company.invoice_sent ? (
      <Badge status="success">Sent</Badge>
    ) : (
      <Badge status="attention">Not Sent</Badge>
    ),
    company.invoice_sent_on
      ? format(company.invoice_sent_on, 'MMMM dd, yyyy')
      : 'N/A',
    company.invoice_sent ? null : (
      <Button primary onClick={() => handleMarkAsSent(company.company_id)}>
        Mark as sent
      </Button>
    ),
  ])

  const allPromoCodes = JSON.parse(window.localStorage.getItem('codes')) || []

  const leadRows = leads.map(lead => {
    return [lead]
  })

  return (
    <Page title="Happy Team Admin">
      <Card sectioned>
        <DisplayText size="small">Leads ({leads.length})</DisplayText>

        <DataTable
          columnContentTypes={['text']}
          headings={['Email']}
          rows={leadRows}
        />
      </Card>
      <Card sectioned>
        <DisplayText size="small">
          Companies List ({companies.length})
        </DisplayText>

        <DataTable
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
          headings={[
            'Company',
            'Plan',
            'Promo',
            'Admin',
            'Email',
            'Invoice sent',
            'Invoice send date',
            '',
          ]}
          rows={customerRows}
        />
      </Card>
    </Page>
  )
}

export default Admin
