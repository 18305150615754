import * as React from 'react';
import {useHappyTeam} from '../../state/index';
import {db, storage} from '../../api/db-config/index';
import createUUID from '../../utils/uuid';

import toast, {Toaster} from 'react-hot-toast';

import {API_ROOT} from '../../api/endpoints/index';

import {
  VocabularyMajor,
  ChatMajor,
  QuestionMarkMajor,
  NoteMinor,
} from '@shopify/polaris-icons';

import {
  Button,
  FormLayout,
  TextField,
  Select,
  Layout,
  Card,
  Checkbox,
  Caption,
  Stack,
  ResourceList,
  ResourceItem,
  TextStyle,
  Badge,
  Page,
  Tooltip,
  Icon,
  DropZone,
  Thumbnail,
  SkeletonBodyText,
} from '@shopify/polaris';

const CompanySettings = () => {
  const {
    state: {
      company_name,
      company_send_dates,
      company_team_members,
      company_tags,
      company_id,
      admin_email,
      company_plan,
      invites,
      member_name,
      custom_logo,
      custom_email_content,
      custom_color,
    },
    dispatch,
  } = useHappyTeam();

  const [companyName, setCompanyName] = React.useState(company_name);
  const [companySendDates, setCompanySendDates] =
    React.useState(company_send_dates);

  const [logo, setLogo] = React.useState(
    custom_logo
      ? `https://firebasestorage.googleapis.com/v0/b/happy-team-de21b.appspot.com/o/images%2F${custom_logo}?alt=media`
      : false,
  );

  const [emailContent, setEmailContent] = React.useState(custom_email_content);
  const [customColor, setCustomColor] = React.useState(
    custom_color ? custom_color : 'ffffff',
  );

  const [newTag, setNewTag] = React.useState('');
  const [newInviteName, setNewInviteName] = React.useState('');
  const [newInviteEmail, setNewInviteEmail] = React.useState('');
  const [newInviteTag, setNewInviteTag] = React.useState(company_tags[0]);

  // PLAN DETAILS & FEATURE ACCESS
  const FEATURE_MULTI_DAY_EMAIL_SEND = company_plan !== '100';
  const FEATURE_CUSTOM_BRANDING = company_plan === '1000';

  const handleSaveCompanyName = () => {
    if (companyName === '') {
      toast.error("company name can't be empty");
      setCompanyName(company_name);

      return;
    }

    dispatch({type: 'setCompanyName', payload: companyName});
    setCompanyName(companyName);
    toast.success('company name saved!');

    db.collection('company').doc(company_id).update({
      company_name: companyName,
    });
  };

  const handleSaveCompanySendDates = (date, value) => {
    const sendDates = {...companySendDates, [date]: value};
    dispatch({type: 'setCompanySendDates', payload: sendDates});
    setCompanySendDates(sendDates);

    db.collection('company')
      .doc(company_id)
      .update({
        company_send_dates: {...sendDates},
      });

    let emails = {};
    company_team_members.map(team_member => {
      emails[`${team_member.id}`] = {
        id: team_member.id,
        email: team_member.email,
        name: team_member.name,
        tag: team_member.hasOwnProperty('tag') ? team_member.tag : false,
      };
    });

    if (value) {
      // add company team members email list to this  day
      db.collection(date)
        .doc(company_id)
        .update({
          emails: {...emails},
        });
    } else {
      // remove company team members email list from this day
      db.collection(date).doc(company_id).update({
        emails: {},
      });
    }
  };

  const handleSaveCompanyTags = () => {
    if (newTag === '') {
      toast.error("tag can't be blank");
      return;
    }

    const companyTags = [...company_tags, newTag];

    dispatch({type: 'setCompanyTags', payload: companyTags});
    setNewTag('');
    toast.success('tag saved!');

    db.collection('company').doc(company_id).update({
      company_tags: companyTags,
    });
  };

  const handleSaveNewTeamMember = () => {
    if (newInviteName === '') {
      toast.error('invitee name required!');
      return;
    }

    if (newInviteEmail === '') {
      toast.error('invitee email required!');
      return;
    }

    if (newInviteTag === '') {
      toast.error('invitee tag required!');
      return;
    }

    const newTeamMemberID = createUUID();

    const newTeamMember = {
      id: newTeamMemberID,
      email: newInviteEmail,
      name: newInviteName,
      tag: newInviteTag,
    };

    const allTeamMembers = [...company_team_members, newTeamMember];

    const allInvites = [...invites, newTeamMember];

    dispatch({type: 'setCompanyTeamMembers', payload: allTeamMembers});
    dispatch({type: 'setInvites', payload: allInvites});

    db.collection('company').doc(company_id).update({
      invites: allInvites,
    });

    db.collection('company')
      .doc(company_id)
      .update({
        company_team_members: allTeamMembers,
      })
      .then(() => {
        let allTeamMembersEmailList = {};
        allTeamMembers.map(team_member => {
          allTeamMembersEmailList[`${team_member.id}`] = {
            id: team_member.id,
            email: team_member.email,
            name: team_member.name,
            tag: team_member.hasOwnProperty('tag') ? team_member.tag : false,
          };
        });

        if (company_send_dates.monday) {
          db.collection('monday')
            .doc(company_id)
            .update({
              emails: {...allTeamMembersEmailList},
            });
        }

        if (company_send_dates.tuesday) {
          db.collection('tuesday')
            .doc(company_id)
            .update({
              emails: {...allTeamMembersEmailList},
            });
        }

        if (company_send_dates.wednesday) {
          db.collection('wednesday')
            .doc(company_id)
            .update({
              emails: {...allTeamMembersEmailList},
            });
        }
        if (company_send_dates.thursday) {
          db.collection('thursday')
            .doc(company_id)
            .update({
              emails: {...allTeamMembersEmailList},
            });
        }

        if (company_send_dates.friday) {
          db.collection('friday')
            .doc(company_id)
            .update({
              emails: {...allTeamMembersEmailList},
            });
        }

        const inviteEndpoint = `${API_ROOT}/invite?to=${newInviteEmail}&name=${newInviteName}&senderName=${member_name}&orgName=${company_name}&orgId=${company_id}&inviteId=${newTeamMemberID}&tag=${newInviteTag}`;
        fetch(inviteEndpoint)
          .then(response => {
            return response.json();
          })
          .then(resp => {
            if (resp.ok) {
              console.log('invite email sent!');
            }
          })
          .finally(() => {
            setNewInviteName('');
            setNewInviteEmail('');
            setNewInviteTag('');
            toast.success('team member invited!');
          });
      });
  };

  const deleteMember = id => {
    const confirmed = window.confirm(
      'Are you sure you want to remove this user?',
    );

    if (confirmed) {
      const filteredTeamMembers = company_team_members.filter(
        member => member.id !== id,
      );

      // TODO: Remove them from invites list if they exist

      const filteredInvites = invites.filter(member => member.id !== id);

      dispatch({type: 'setCompanyTeamMembers', payload: filteredTeamMembers});
      dispatch({type: 'setInvites', payload: filteredInvites});

      db.collection('company').doc(company_id).update({
        invites: filteredInvites,
      });

      db.collection('company')
        .doc(company_id)
        .update({
          company_team_members: filteredTeamMembers,
        })
        .then(() => {
          let allTeamMembersEmailList = {};
          filteredTeamMembers.map(team_member => {
            allTeamMembersEmailList[`${team_member.id}`] = {
              id: team_member.id,
              email: team_member.email,
              name: team_member.name,
              tag: team_member.hasOwnProperty('tag') ? team_member.tag : false,
            };
          });

          if (company_send_dates.monday) {
            db.collection('monday')
              .doc(company_id)
              .update({
                emails: {...allTeamMembersEmailList},
              });
          }

          if (company_send_dates.tuesday) {
            db.collection('tuesday')
              .doc(company_id)
              .update({
                emails: {...allTeamMembersEmailList},
              });
          }

          if (company_send_dates.wednesday) {
            db.collection('wednesday')
              .doc(company_id)
              .update({
                emails: {...allTeamMembersEmailList},
              });
          }
          if (company_send_dates.thursday) {
            db.collection('thursday')
              .doc(company_id)
              .update({
                emails: {...allTeamMembersEmailList},
              });
          }
          if (company_send_dates.friday) {
            db.collection('friday')
              .doc(company_id)
              .update({
                emails: {...allTeamMembersEmailList},
              });
          }
        });

      setNewInviteName('');
      setNewInviteEmail('');
      setNewInviteTag('');
      toast.success('team member deleted!');
    }
  };

  const handleSaveColor = () => {
    dispatch({type: 'setCustomColor', payload: customColor});
    db.collection('company')
      .doc(company_id)
      .get()
      .then(doc => {
        if (doc.exists) {
          const companyData = doc.data();

          if (companyData.hasOwnProperty('custom_logo')) {
            // update field
            console.log('update specific field');
            db.collection('company').doc(company_id).update({
              custom_color: customColor,
            });
          } else {
            const company = {
              ...companyData,
              custom_color: customColor,
            };

            db.collection('company')
              .doc(company_id)
              .update({
                ...company,
              });
          }

          toast.success('brand color saved!');
        }
      });
  };

  const handleSaveBranding = () => {
    dispatch({type: 'setCustomEmailContent', payload: emailContent});

    // query for company..
    // check if new fields exists
    // if they don't copy the full company object
    // with the new fields over and save..

    db.collection('company')
      .doc(company_id)
      .get()
      .then(doc => {
        if (doc.exists) {
          const companyData = doc.data();

          if (companyData.hasOwnProperty('custom_logo')) {
            // update field
            console.log('update specific field');
            db.collection('company').doc(company_id).update({
              custom_email_content: emailContent,
            });
          } else {
            const company = {
              ...companyData,
              custom_email_content: emailContent,
            };

            db.collection('company')
              .doc(company_id)
              .update({
                ...company,
              });
          }

          toast.success('email content saved!');
        }
      });
  };

  const [files, setFiles] = React.useState([]);

  const handleDropZoneDrop = React.useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles(files => [...files, ...acceptedFiles]),
    [],
  );

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const handleSaveLogo = e => {
    const logoFileName = `company_logo_${company_id}_img_${files[0].name}`;
    const originalFileName = files[0].name;

    const uploadTask = storage.ref(`/images/${logoFileName}`).put(files[0]);

    uploadTask.on(
      'state_changed',
      snapShot => {
        //takes a snap shot of the process as it is happening
        console.log(snapShot);
      },
      err => {
        //catches the errors
        console.log(err);
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref('images')
          .child(logoFileName)
          .getDownloadURL()
          .then(fireBaseUrl => {
            console.log('FIREBASE URL', fireBaseUrl);

            const tokenParamIndex = fireBaseUrl.indexOf('&token');
            const parsedUrl = fireBaseUrl.slice(0, tokenParamIndex);

            // save the logo file name
            dispatch({type: 'setCompanyLogo', payload: logoFileName});

            db.collection('company')
              .doc(company_id)
              .get()
              .then(doc => {
                if (doc.exists) {
                  const companyData = doc.data();

                  if (companyData.hasOwnProperty('custom_logo')) {
                    // update field
                    console.log('update specific field');
                    db.collection('company').doc(company_id).update({
                      custom_logo: logoFileName,
                    });
                  } else {
                    const company = {
                      ...companyData,
                      custom_logo: logoFileName,
                    };

                    console.log();

                    setLogo(
                      `https://firebasestorage.googleapis.com/v0/b/happy-team-de21b.appspot.com/o/images%2F${logoFileName}?alt=media`,
                    );

                    db.collection('company')
                      .doc(company_id)
                      .update({
                        ...company,
                      });
                  }
                }
              });
          });
      },
    );
  };

  const fileUpload = !files.length && (
    <div style={{padding: '1rem'}}>
      <Stack distribution="center">
        <Stack vertical>
          <Button>Add files</Button>
          <TextStyle variation="subdued">or drop to upload</TextStyle>
        </Stack>
      </Stack>
    </div>
  );
  const uploadedFiles = files.length > 0 && (
    <Stack vertical>
      {files.map((file, index) => (
        <Stack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name} <Caption>{file.size} bytes</Caption>
          </div>
        </Stack>
      ))}
    </Stack>
  );

  const hasNoDaysChecked =
    !companySendDates.monday &&
    !companySendDates.tuesday &&
    !companySendDates.wednesday &&
    !companySendDates.thursday &&
    !companySendDates.friday;

  return (
    <Page
      title="Company Settings"
      breadcrumbs={[{content: 'Dashboard', url: '/dashboard'}]}
      actionGroups={[
        {
          title: 'Need help?',
          actions: [
            {
              content: 'View docs',
              onAction: () => {
                window.location.href = '/kb';
              },
              icon: VocabularyMajor,
            },
            {
              content: 'Contact support',
              onAction: () => {},
              icon: ChatMajor,
            },
          ],
        },
      ]}
    >
      {/* <Frame> */}
      <div style={{marginTop: '24px'}}>
        <Toaster position="top-center" />
        <Layout>
          <Layout.AnnotatedSection
            title="General"
            description="Set how you want your company name to appear in emails, and when you'd like our emails to be sent"
          >
            <Card sectioned title="Company">
              <FormLayout>
                <TextField
                  type="text"
                  label="Display name"
                  onChange={value => setCompanyName(value)}
                  value={companyName}
                  connectedRight={
                    <Button primary onClick={() => handleSaveCompanyName()}>
                      Save
                    </Button>
                  }
                />
              </FormLayout>
            </Card>

            <Card
              sectioned
              title={
                <div
                  className="premium-feature"
                  style={{display: 'flex', flexDirection: 'column'}}
                >
                  <div style={{display: 'flex'}}>
                    <b style={{fontSize: '1.6rem', paddingRight: '4px'}}>
                      Email send dates
                    </b>
                    {!FEATURE_MULTI_DAY_EMAIL_SEND && (
                    <Tooltip content="Multi-day send dates are only available to Growth and Pro plan accounts">
                      <Badge status="success">
                        {' '}
                        <Icon source={QuestionMarkMajor} />
                        Premium feature
                      </Badge>
                    </Tooltip>
                  )}
                  </div>

                  <div>All survey emails go out around 11am EST (UTC -5). <br /> Custom time zones coming soon!</div>

                  
                </div>
              }
            >
              <FormLayout>
                <Stack>
                  <Checkbox
                    label="Monday"
                    checked={companySendDates.monday}
                    onChange={e => handleSaveCompanySendDates('monday', e)}
                    disabled={
                      !hasNoDaysChecked &&
                      !companySendDates.monday &&
                      !FEATURE_MULTI_DAY_EMAIL_SEND
                    }
                  />

                  <Checkbox
                    label="Tuesday"
                    checked={companySendDates.tuesday}
                    onChange={e => handleSaveCompanySendDates('tuesday', e)}
                    disabled={
                      !hasNoDaysChecked &&
                      !companySendDates.tuesday &&
                      !FEATURE_MULTI_DAY_EMAIL_SEND
                    }
                  />

                  <Checkbox
                    label="Wednesday"
                    checked={companySendDates.wednesday}
                    onChange={e => handleSaveCompanySendDates('wednesday', e)}
                    disabled={
                      !hasNoDaysChecked &&
                      !companySendDates.wednesday &&
                      !FEATURE_MULTI_DAY_EMAIL_SEND
                    }
                  />

                  <Checkbox
                    label="Thursday"
                    checked={companySendDates.thursday}
                    onChange={e => handleSaveCompanySendDates('thursday', e)}
                    disabled={
                      !hasNoDaysChecked &&
                      !companySendDates.thursday &&
                      !FEATURE_MULTI_DAY_EMAIL_SEND
                    }
                  />

                  <Checkbox
                    label="Friday"
                    checked={companySendDates.friday}
                    onChange={e => handleSaveCompanySendDates('friday', e)}
                    disabled={
                      !hasNoDaysChecked &&
                      !companySendDates.friday &&
                      !FEATURE_MULTI_DAY_EMAIL_SEND
                    }
                  />
                </Stack>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </div>

      <div style={{marginTop: '48px'}}>
        <Layout>
          <Layout.AnnotatedSection
            title="Tags"
            description="Use tags to categorize your survey responses from your team"
          >
            <Card sectioned>
              <FormLayout>
                <TextField
                  type="text"
                  label="Create new tag"
                  onChange={value => setNewTag(value)}
                  value={newTag}
                  connectedRight={
                    <Button primary onClick={() => handleSaveCompanyTags()}>
                      Save
                    </Button>
                  }
                />

                <ResourceList
                  resourceName={{
                    singular: 'blog post',
                    plural: 'blog posts',
                  }}
                  items={company_tags}
                  renderItem={item => {
                    return (
                      <ResourceItem
                        accessibilityLabel={`View details for ${item}`}
                        name={item}
                      >
                        <Stack>
                          <Stack.Item fill>
                            <h3>
                              <TextStyle variation="strong">{item}</TextStyle>
                            </h3>
                          </Stack.Item>
                        </Stack>
                      </ResourceItem>
                    );
                  }}
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </div>

      <div style={{marginTop: '48px'}}>
        <Layout>
          <Layout.AnnotatedSection
            title="Team members"
            description="Invite and manage your company employees"
          >
            <Card sectioned>
              <FormLayout>
                <b>
                  {parseInt(company_plan) -
                    parseInt(company_team_members.length)}
                  /{company_plan} seats available
                </b>

                <Stack>
                  <Stack.Item fill>
                    <Select
                      label="Tag"
                      options={company_tags.map(tag => ({
                        label: tag,
                        value: tag,
                      }))}
                      onChange={value => setNewInviteTag(value)}
                      value={newInviteTag}
                      requiredIndicator
                    />
                  </Stack.Item>
                  <Stack.Item fill>
                    <TextField
                      type="text"
                      label="Invitee name"
                      onChange={value => setNewInviteName(value)}
                      value={newInviteName}
                      requiredIndicator
                    />
                  </Stack.Item>
                  <Stack.Item fill>
                    <TextField
                      type="text"
                      label="Invitee email"
                      onChange={value => setNewInviteEmail(value)}
                      value={newInviteEmail}
                      requiredIndicator
                      connectedRight={
                        <Button
                          primary
                          onClick={() => handleSaveNewTeamMember()}
                        >
                          Send Invite
                        </Button>
                      }
                    />
                  </Stack.Item>
                </Stack>

                <ResourceList
                  items={company_team_members}
                  renderItem={item => {
                    return (
                      <ResourceItem
                        accessibilityLabel={`Team member ${item.name}`}
                        name={item}
                      >
                        <Stack>
                          <Stack.Item fill>
                            <h3>
                              <TextStyle variation="strong">
                                {item.name}
                              </TextStyle>
                              {item.email === admin_email && (
                                <span style={{paddingLeft: '6px'}}>
                                  <Badge status="info">Admin</Badge>
                                </span>
                              )}
                            </h3>
                            <div>
                              {item.email} {item.tag && `| ${item.tag}`}
                            </div>
                          </Stack.Item>

                          {item.email !== admin_email && (
                            <Stack.Item>
                              <Button
                                size="slim"
                                outline
                                onClick={() => deleteMember(item.id)}
                                destructive
                              >
                                Remove
                              </Button>
                            </Stack.Item>
                          )}
                        </Stack>
                      </ResourceItem>
                    );
                  }}
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </div>

      <div style={{marginTop: '48px'}}>
        <Layout>
          <Layout.AnnotatedSection
            title="Customizations"
            description="Set the look and feel of survey emails to match your brand"
          >
            <Card
              title={
                <div className="premium-feature" style={{display: 'flex'}}>
                  <b style={{fontSize: '1.6rem', paddingRight: '4px'}}>
                    Branding
                  </b>
                  {!FEATURE_CUSTOM_BRANDING && (
                    <Tooltip content="This feature is only available to Pro plan accounts">
                      <Badge status="success">
                        {' '}
                        <Icon source={QuestionMarkMajor} />
                        Premium feature
                      </Badge>
                    </Tooltip>
                  )}
                </div>
              }
              sectioned
            >
              <div className="branding-preview-grid">
                <FormLayout>
                  <DropZone
                    label="Logo"
                    type="image"
                    allowMultiple={false}
                    variableHeight
                    onDrop={handleDropZoneDrop}
                    disabled={!FEATURE_CUSTOM_BRANDING}
                  >
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>

                  {FEATURE_CUSTOM_BRANDING && (
                    <Button onClick={handleSaveLogo} primary size="medium">
                      Save
                    </Button>
                  )}

                  <TextField
                    type="text"
                    label="Header color"
                    disabled={!FEATURE_CUSTOM_BRANDING}
                    value={customColor}
                    onChange={value => setCustomColor(value)}
                    prefix="#"
                  />
                  {FEATURE_CUSTOM_BRANDING && (
                    <Button onClick={handleSaveColor} primary size="medium">
                      Save
                    </Button>
                  )}

                  <TextField
                    type="text"
                    label="Custom text"
                    helpText="Appears below HappyTeam intro text"
                    multiline={3}
                    disabled={!FEATURE_CUSTOM_BRANDING}
                    value={emailContent}
                    onChange={value => setEmailContent(value)}
                  />

                  {FEATURE_CUSTOM_BRANDING && (
                    <Button onClick={() => handleSaveBranding()} primary>
                      Save
                    </Button>
                  )}
                </FormLayout>

                <div>
                  <div className="branding-mock-email">
                    <div className="mock-email">
                      <div className="mock-email-body">
                        <div
                          className="mock-email-logo-banner"
                          style={{backgroundColor: '#' + customColor}}
                        >
                          <img src={logo ? logo : 'https://bit.ly/2VYBgPk'} />
                        </div>
                        <div className="mock-email-body-wrap">
                          <SkeletonBodyText lines={2} />
                          <span>{emailContent}</span>
                          <SkeletonBodyText lines={4} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <small
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      color: '#252525',
                      opacity: '0.5',
                      display: 'block',
                    }}
                  >
                    Email preview
                  </small>
                </div>
              </div>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </div>
    </Page>
  );
};

export default CompanySettings;
