import * as React from 'react';
import {useParams} from 'react-router-dom';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {db, auth} from '../../../api/db-config/index';
import {API_ROOT} from '../../../api/endpoints/index';
import {useHappyTeam} from '../../../state/index';
import createUUID from '../../../utils/uuid';

import WordMark from '../../../assets/logo-new.png';

import {
  Page,
  TextField,
  Layout,
  Card,
  Button,
  FormLayout,
  DisplayText,
  Select,
  Banner,
} from '@shopify/polaris';

import PaymentForm from './payment-form';

const stripePromise = loadStripe(
  'pk_live_51JNLdKCqLkH6nYdCrn4n6VPHUeSKmmCzKtuMsTs17flymMhrM05LJD4G9t2WzuW3cV2qprZp4SPYkBsvPAUOVekE00dgMA3eKG',
);

const CompanySignup = () => {
  const [companyName, setCompanyName] = React.useState('');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [plan, setPlan] = React.useState('1000');
  const [appSumoCode, setAppSumoCode] = React.useState('');

  const [promoCodeValidMessage, setPromoCodeValidMessage] =
    React.useState(null);

  const [error, setError] = React.useState('');
  const [stripeClientSecret, setStripeClientSecret] = React.useState(null);

  const {dispatch} = useHappyTeam();

  const params = useParams();
  const {email_address} = params;

  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#111',
      colorBackground: '#252525',
    },
  };
  const options = {
    clientSecret: stripeClientSecret,
    // appearance,
  };

  const paymentIntentSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret',
  );

  React.useEffect(() => {
    if (paymentIntentSecret) return;
    window.localStorage.setItem('happy_team_signup_COMPANY', companyName);
  }, [companyName]);

  React.useEffect(() => {
    if (paymentIntentSecret) return;
    window.localStorage.setItem('happy_team_signup_NAME', name);
  }, [name]);

  React.useEffect(() => {
    if (paymentIntentSecret) return;
    window.localStorage.setItem('happy_team_signup_EMAIL', email);
  }, [email]);

  React.useEffect(() => {
    if (paymentIntentSecret) return;
    window.localStorage.setItem('happy_team_signup_PW', password);
  }, [password]);

  const fetchStripePaymentIntent = async () => {
    try {
      const response = await fetch(`${API_ROOT}/create-payment-intent`);
      const {clientSecret} = await response.json();

      console.log('client secret', clientSecret);

      setStripeClientSecret(clientSecret);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchStripePaymentIntent();

    if (typeof email_address !== 'undefined') {
      setEmail(email_address);

      const LEAD_ID = createUUID();
      // save lead
      db.collection('leads').doc(LEAD_ID).set({email: email_address});
    }
  }, []);

  const validatePromoCode = () => {
    setPromoCodeValidMessage(null);

    if (!appSumoCode || appSumoCode === '') {
      setPromoCodeValidMessage('INVALID');
      return;
    }

    const docRef = db.collection('app_sumo_codes').doc(appSumoCode);

    docRef
      .get()
      .then(doc => {
        // Document was found in the cache. If no cached document exists,
        // an error will be returned to the 'catch' block below.

        if (doc.exists) {
          if (!doc.data().used) {
            setPromoCodeValidMessage('VALID');
          } else {
            setPromoCodeValidMessage('USED');
          }
        } else {
          setPromoCodeValidMessage('INVALID');
          return;
        }
      })
      .catch(error => {
        setPromoCodeValidMessage('INVALID');
      });
  };

  const completeSignup = promoCodeValid => {
    try {
      const ht_email = window.localStorage.getItem('happy_team_signup_EMAIL');
      const ht_password = window.localStorage.getItem('happy_team_signup_PW');
      const ht_company = window.localStorage.getItem(
        'happy_team_signup_COMPANY',
      );
      const ht_name = window.localStorage.getItem('happy_team_signup_NAME');

      console.log('payment succeeded, create user with:', {
        ht_email,
        ht_password,
        ht_company,
        ht_name,
      });

      auth
        .createUserWithEmailAndPassword(ht_email, ht_password)
        .then(userCredential => {
          const user = userCredential.user;
          const COMPANY_ID = createUUID();
          const ADMIN_USER_ID = user.uid;

          // add new company to happy team admin
          db.collection('new-companies').doc(COMPANY_ID).set({
            company_id: COMPANY_ID,
            company_name: ht_company,
            company_admin: ht_name,
            company_admin_id: ADMIN_USER_ID,
            company_email: ht_email,
            plan: '1000',
            createdOn: Date.now(),
            invoice_sent: false,
            invoice_sent_on: false,
            is_app_sumo: promoCodeValid,
            app_sumo_code: appSumoCode,
          });

          // save user ref
          db.collection('users').doc(ADMIN_USER_ID).set({
            company_id: COMPANY_ID,
            user_id: ADMIN_USER_ID,
            name: ht_name,
            email: ht_email,
            role: 'ADMIN',
          });

          // Create company
          db.collection('company')
            .doc(COMPANY_ID)
            .set({
              company_id: COMPANY_ID,
              company_name: ht_company,
              admin_user_id: ADMIN_USER_ID,
              admin_email: ht_email,
              plan: '1000',
              is_app_sumo: promoCodeValid,
              app_sumo_code: appSumoCode,
              unpaid_invoice: false,
              company_send_dates: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
              },
              invites: [],
              company_team_members: [
                {
                  id: ADMIN_USER_ID,
                  name: ht_name,
                  email: ht_email,
                },
              ],
              company_tags: [
                'engineering',
                'product',
                'customer support',
                'design',
                'sales',
                'marketing',
              ],
              custom_logo: false,
              custom_email_content: false,
              custom_color: false,
            })
            .then(() => {
              // update company send date collections
              const DAYS_TO_SEND = [
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
              ];

              DAYS_TO_SEND.forEach(day => {
                db.collection(day).doc(COMPANY_ID).set({
                  emails: {},
                });
              });

              // save all data to state
              dispatch({type: 'setCompanyID', payload: COMPANY_ID});
              dispatch({
                type: 'setCompanyAdminEmail',
                payload: ht_email,
              });
              dispatch({type: 'setCustomEmailContent', payload: false});
              dispatch({type: 'setCustomColor', payload: false});
              dispatch({type: 'setCompanyLogo', payload: false});

              dispatch({
                type: 'setCompanyPlan',
                payload: '1000',
              });

              dispatch({type: 'setCompanyName', payload: ht_company});
              dispatch({
                type: 'setCompanyTags',
                payload: [
                  'engineering',
                  'product',
                  'customer support',
                  'design',
                  'sales',
                  'marketing',
                ],
              });

              dispatch({
                type: 'setCompanySendDates',
                payload: {
                  monday: false,
                  tuesday: false,
                  wednesday: false,
                  thursday: false,
                  friday: false,
                },
              });
              dispatch({
                type: 'setCompanyTeamMembers',
                payload: [
                  {
                    id: ADMIN_USER_ID,
                    name: ht_name,
                    email: ht_email,
                  },
                ],
              });

              dispatch({type: 'setMemberName', payload: ht_name});
              dispatch({type: 'setMemberRole', payload: 'ADMIN'});
              dispatch({type: 'setMemberUid', payload: ADMIN_USER_ID});
              dispatch({type: 'setMemberEmail', payload: ht_email});

              fetch(
                `${API_ROOT}/welcome-company?to=${ht_email}&name=${ht_name}`,
              )
                .then(response => {
                  return response.json();
                })
                .then(resp => {
                  if (resp.ok) {
                    window.localStorage.removeItem('happy_team_signup_EMAIL');
                    window.localStorage.removeItem('happy_team_signup_PW');
                    window.localStorage.removeItem('happy_team_signup_COMPANY');
                    window.localStorage.removeItem('happy_team_signup_NAME');

                    // redirect to company settings page.
                    window.location.href = '/company';
                  }
                });
            })
            .catch(error => {
              console.error('Error writing document: ', error);
            });
        })
        .catch(error => {
          console.log('Error creating user', error);
          setError(error);
          return;
        });
    } catch (error) {
      setError(error);
      return;
    }
  };

  const initSignup = async () => {
    setError('');

    if (email === '' || password === '' || companyName === '') {
      setError('Please complete all required field');
      return;
    }

    const isAppSumo = appSumoCode !== '';

    if (isAppSumo) {
      // validate the code exists
      const docRef = db.collection('app_sumo_codes').doc(appSumoCode);

      docRef
        .get()
        .then(doc => {
          // Document was found in the cache. If no cached document exists,
          // an error will be returned to the 'catch' block below.

          if (doc.exists) {
            if (!doc.data().used) {
              docRef
                .update({
                  email: email,
                  used: true,
                })
                .then(() => {
                  completeSignup(true);
                })
                .catch(() => {
                  setError(
                    'Could not apply promo code. Please reach out to support.',
                  );
                });
            } else {
              setError('Promo code has already been used');
            }
          } else {
            setError('Invalid promo code');
            return;
          }
        })
        .catch(error => {
          console.log('Error getting cached document:', error);
          setError('Invalid promo code');
        });
    } else {
      completeSignup(false);
    }
  };

  const renderPrice = () => {
    if (promoCodeValidMessage === 'VALID') {
      return <b>$0.00</b>;
    }
    return <b>$49.00 USD</b>;
  };

  const formInvalid =
    name === '' || email === '' || password === '' || companyName === '';

  return (
    <div className="checkout">
      <Page>
        <h1>Join today and save! 🎁</h1>
        <p style={{margin: 0, marginBottom: '24px', fontSize: '18px'}}>
          <span style={{fontSize: '24px'}}></span>
          For a limited time while we're in beta, we're offering all early users
          a pay-only-once, lifetime deal to Happy Team. For <b>$49.00</b>,
          you'll get access to all premium features today, and all new ones in
          the future. Forever. Pretty sweet right?
        </p>

        {paymentIntentSecret && (
          <div style={{marginBottom: '16px'}}>
            <Banner title="Account created! Redirecting..." status="success" />
          </div>
        )}

        {
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <FormLayout>
                  <h2 style={{fontSize: '22px'}}>Account details</h2>

                  <TextField
                    type="text"
                    label="Business name"
                    onChange={e => setCompanyName(e)}
                    value={companyName}
                    requiredIndicator
                  />

                  <TextField
                    type="text"
                    label="Your name"
                    onChange={e => setName(e)}
                    value={name}
                    requiredIndicator
                  />

                  <TextField
                    type="email"
                    label="Email"
                    onChange={e => setEmail(e)}
                    value={email}
                    requiredIndicator
                  />

                  <TextField
                    type="password"
                    label="Password"
                    value={password}
                    onChange={e => setPassword(e)}
                    requiredIndicator
                  />

                  <hr />

                  <h2 style={{fontSize: '22px'}}>Payment</h2>

                  {promoCodeValidMessage &&
                    promoCodeValidMessage === 'VALID' && (
                      <Banner
                        title="Promo code applied! No payment due."
                        status="success"
                      />
                    )}

                  {promoCodeValidMessage &&
                    promoCodeValidMessage === 'INVALID' && (
                      <Banner title="Invalid promo code" status="critical" />
                    )}

                  {promoCodeValidMessage &&
                    promoCodeValidMessage === 'USED' && (
                      <Banner
                        title="Promo code has already been used"
                        status="critical"
                      />
                    )}

                  {promoCodeValidMessage !== 'VALID' && (
                    <div className="promo-wrap">
                      <p>
                        Due today: {renderPrice()} – Lifetime access to
                        HappyTeam premium.
                      </p>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          alignItems: 'flex-end',
                        }}
                      >
                        <TextField
                          type="text"
                          label="Promo Code"
                          value={appSumoCode}
                          onChange={e => setAppSumoCode(e)}
                          suffix={() => <div>...</div>}
                        />
                        <button
                          onClick={validatePromoCode}
                          size="slim"
                          style={{
                            maxHeight: '36px',
                            height: '36px',
                            background: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            marginLeft: '8px',
                          }}
                        >
                          Apply Promo
                        </button>
                      </div>
                    </div>
                  )}

                  {stripeClientSecret && promoCodeValidMessage !== 'VALID' && (
                    <Elements options={options} stripe={stripePromise}>
                      <PaymentForm
                        secret={stripeClientSecret}
                        handleSignup={() => completeSignup(false)}
                        disabled={formInvalid}
                      />
                    </Elements>
                  )}

                  {error && <div style={{color: 'red'}}>{error}</div>}

                  {promoCodeValidMessage === 'VALID' && (
                    <Button primary fullWidth onClick={initSignup}>
                      Signup
                    </Button>
                  )}
                </FormLayout>
              </Card>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '18px',
                }}
              >
                <img src={WordMark} style={{height: 22, width: 'auto'}} />
              </div>
            </Layout.Section>
          </Layout>
        }
      </Page>
    </div>
  );
};

export default CompanySignup;
