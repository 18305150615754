import React, {useEffect, useState} from 'react';
import {PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';

import {
  Page,
  TextField,
  Layout,
  Card,
  Button,
  FormLayout,
  DisplayText,
  Select,
} from '@shopify/polaris';

export default function CheckoutForm({handleSignup, disabled}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      // top up their account with credits based off the amount

      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          alert('succeeded');
          handleSignup();
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          alert('processing');
          handleSignup();
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async e => {
    alert('handle submit');
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // TODO: this will redirect the user and refresh the page, we should save the form state in local storage.
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occured.');
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form">
      <div>
        <PaymentElement id="payment-element" />

        <div style={{marginTop: '12px'}}>
          <Button
            onClick={handleSubmit}
            fullWidth
            primary
            disabled={isLoading || !stripe || !elements || disabled}
            id="submit"
          >
            Signup
          </Button>
        </div>
      </div>

      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" style={{color: '#fe87a1'}}>
          {message}
        </div>
      )}
    </form>
  );
}
