import * as React from 'react';

import HappyTeamLogo from '../../assets/logo-new.png';

import {Button, Icon, Card, Badge, TextField, Banner} from '@shopify/polaris';
import {
  FollowUpEmailMajor,
  SmileyHappyMajor,
  AnalyticsMajor,
} from '@shopify/polaris-icons';

import DashboardImageOne from '../../assets/dashboard_1.png';
import DashboardImageTwo from '../../assets/dashboard_2.svg';
import DashboardImageThree from '../../assets/dashboard_3.svg';

import MarketingImageOne from '../../assets/marketing_1.jpeg';
import MarketingImageTwo from '../../assets/marketing_2.jpeg';

import DashboardGif from '../../assets/dashboard-gif.gif';
import SettingsGif from '../../assets/settings.gif';
import SurveyResponseGif from '../../assets/survey-response.gif';

import PeekOne from '../../assets/peek-1.png';
import PeekTwo from '../../assets/peek-2.png';
import PeekThree from '../../assets/peek-3.png';

import BrandMark from '../../assets/logo-new.png';

const MarketingPage = () => {
  const featureOneImageURL = `url(${MarketingImageOne})`;
  const featureTwoImageURL = `url(${MarketingImageTwo})`;

  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  const [email, setEmail] = React.useState('');

  return (
    <div>
      <div
        onClick={() => {
          window.location.href = '/new-company';
        }}
        className="promo"
      >
        <span style={{marginRight: '4px', borderBottom: '1px solid #fff'}}>
          🎁 Get LIFETIME access for $49 through April
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="#fff"
        >
          <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
        </svg>
      </div>
      {showMobileMenu && (
        <div className="mobile-menu">
          <div className="mobile-menu-container">
            <div className="close" onClick={() => setShowMobileMenu(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
              </svg>
            </div>

            <a
              className="link"
              href="#features"
              onClick={() => {
                setShowMobileMenu(false);
              }}
            >
              Features
            </a>
            <a
              className="link"
              href="#pricing"
              onClick={() => {
                setShowMobileMenu(false);
              }}
            >
              Pricing
            </a>
            <a
              className="link"
              href="#roadmap"
              onClick={() => {
                setShowMobileMenu(false);
              }}
            >
              Roadmap
            </a>
            <a className="link" href="/sign-in">
              Login
            </a>

            <Button
              size="large"
              fullWidth
              onClick={() => {
                window.location.href = '/new-company';
              }}
            >
              <a href="/new-company">Sign up</a>
            </Button>

            <div className="flex-row" style={{marginTop: '48px'}}>
              <div style={{padding: '0px 6px'}}>
                <a href="https://twitter.com/GetHappyTeam">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#3b7b6e"
                      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                    />
                  </svg>
                </a>
              </div>
              <div style={{padding: '0px 6px'}}>
                <a href="https://www.linkedin.com/company/gethappyteam">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#3b7b6e"
                      d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="navbar">
        <div className="container">
          <div className="navbar-content">
            <img src={HappyTeamLogo} />

            <div
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              className="mobile-menu-icon"
            >
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path
                  d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z"
                  fill="#1040e2"
                />
                <path d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z" />
              </svg>
            </div>
            <div className="nav-links">
              <a href="#features">Features</a>
              <a href="#pricing">Pricing</a>
              <a href="#roadmap">Roadmap</a>
              <a href="/sign-in">Login</a>
              <a
                href="https://twitter.com/GetHappyTeam"
                style={{display: 'flex', alignItems: 'center'}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#3b7b6e"
                    d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                  />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/gethappyteam"
                style={{display: 'flex', alignItems: 'center'}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#3b7b6e"
                    d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                  />
                </svg>
              </a>
              <Button
                onClick={() => {
                  window.location.href = '/new-company';
                }}
              >
                <a href="/new-company">Sign up</a>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-bg" data-aos="fade-in">
        <div className="container">
          <div className="section">
            <div className="hero">
              <h1>The best way to keep a pulse on your team's well-being</h1>
              <h2>
                Built for managers and leaders to ensure their organization is
                seen and heard.
              </h2>
              <div className="flex-row button-row">
                <TextField
                  type="email"
                  value={email}
                  requiredIndicator
                  placeholder="Email address"
                  onChange={val => setEmail(val)}
                  connectedRight={
                    <Button
                      primary
                      onClick={() =>
                        (window.location.href = `/new-company/${email}`)
                      }
                    >
                      Get started
                    </Button>
                  }
                />
              </div>

              <div className="hero-illo-container">
                <img
                  className="one"
                  data-aos="fade-in"
                  data-aos-delay="500"
                  src={DashboardImageOne}
                />
                <img
                  className="two"
                  data-aos="fade-right"
                  data-aos-delay="500"
                  src={DashboardImageTwo}
                />
                <img
                  className="three"
                  data-aos="fade-left"
                  data-aos-delay="500"
                  src={DashboardImageThree}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="features" className="feature-container white">
        <div className="container">
          <div className="feature-grid" data-aos="fade-up">
            <div className="feature-image-container">
              <div
                className="feature-image"
                style={{
                  backgroundImage: featureOneImageURL,
                }}
              ></div>
            </div>
            <div>
              <label>
                <span>OUR PRODUCT</span>
              </label>
              <div className="feature-grid-content">
                <h2>Gives you insight into your team's health </h2>
                <p>
                  Whether your office is remote or not, putting your team's
                  mental well-being first should be your top priority. We enable
                  you to do this in a simple, no-frills way:
                </p>
                <div className="feature-checklist">
                  <div className="feature-item">
                    <div className="feature-icon">
                      <Icon color="primary" source={FollowUpEmailMajor} />
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div>Mood survey sent to your team members</div>
                      <p>
                        Team member's will receive an email asking how they are
                        feeling. You can configure this to send daily, once a
                        week, twice a week, etc. You can also add branding
                        customizations as well.
                      </p>
                    </div>
                  </div>

                  <div className="feature-item">
                    <div className="feature-icon">
                      <Icon color="primary" source={SmileyHappyMajor} />
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div>All responses are 100% anonymous</div>
                      <p>
                        They don't even have to log in to respond. In addition
                        to giving a mood score, members can leave a comment as
                        well.
                      </p>
                    </div>
                  </div>

                  <div className="feature-item">
                    <div className="feature-icon">
                      <Icon color="primary" source={AnalyticsMajor} />
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div>Charted in your team dashboard</div>
                      <p>
                        All responses get logged and charted into visual graphs
                        to show you the bigger picture of your team's overall
                        happiness. Team members get access to a company
                        dashboard to view scores, trends and to promote
                        transparency.
                      </p>
                    </div>
                  </div>
                </div>

                <Button
                  onClick={() => {
                    window.location.href = '/new-company';
                  }}
                  size="large"
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="feature-container">
        <div className="container">
          <div className="feature-grid" data-aos="fade-up">
            <div>
              <label>
                <span>OUR FEATURES</span>
              </label>
              <div className="feature-grid-content">
                <h2>Simple, but powerful</h2>
                <p>
                  We've designed HappyTeam to make it easy to understand how
                  your team is doing. By removing unnecessary fluff, we're able
                  to give you just the right amount of information you need to
                  get a real-time pulse check.
                </p>
                <div className="feature-checklist">
                  <div className="feature-item">
                    <div className="feature-icon">
                      <Icon color="primary" source={FollowUpEmailMajor} />
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div>Total happiness score </div>
                      <p>
                        An average of all responses by mood, tallied since your
                        company signed up for HappyTeam.
                      </p>
                    </div>
                  </div>

                  <div className="feature-item">
                    <div className="feature-icon">
                      <Icon color="primary" source={SmileyHappyMajor} />
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div>Weekly trends </div>
                      <p>
                        Gain insights week by week on the overall sentiment of
                        your team.
                      </p>
                    </div>
                  </div>

                  <div className="feature-item">
                    <div className="feature-icon">
                      <Icon color="primary" source={AnalyticsMajor} />
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div>Department satisfaction</div>
                      <p>
                        Drill down even further by individual teams or
                        departments.
                      </p>
                    </div>
                  </div>

                  <a
                    style={{
                      display: 'block',
                      marginTop: '48px',
                      color: '#3b7b6e',
                    }}
                    href="#roadmap"
                  >
                    See all features planned
                  </a>
                </div>

                <Button
                  onClick={() => {
                    window.location.href = '/new-company';
                  }}
                  size="large"
                >
                  Get Started
                </Button>
              </div>
            </div>
            <div className="feature-image-container">
              <div
                className="feature-image"
                style={{
                  backgroundImage: featureTwoImageURL,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className="feature-container white">
        <div className="container">
          <div className="demo-title">
            <h4
              style={{
                color: '#3b7b6e',
                textAlign: 'center',
                marginBottom: '12px',
              }}
            >
              Take a peek behind the curtain 👀
            </h4>
            <p
              style={{
                textAlign: 'center',
                marginBottom: '32px',
                fontSize: '18px',
              }}
            >
              Everything you need to to ensure your organization is seen and
              heard.
            </p>
          </div>

          <div className="demo-container" data-aos="fade-up">
            <div>
              <Card sectioned>
                <div
                  style={{
                    padding: '0px',
                    display: 'flex',
                    background: '#f5f5f5',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: '6px',
                  }}
                >
                  <img src={DashboardGif} />
                </div>
                <div style={{marginTop: '6px'}}>Team dashboard view</div>
              </Card>
            </div>

            <div>
              <Card sectioned>
                <div
                  style={{
                    padding: '0px',
                    display: 'flex',
                    background: '#f5f5f5',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: '6px',
                  }}
                >
                  <img src={SettingsGif} />
                </div>
                <div style={{marginTop: '6px'}}>Organization settings view</div>
              </Card>
            </div>

            <div>
              <Card sectioned>
                <div
                  style={{
                    padding: '0px',
                    display: 'flex',
                    background: '#f5f5f5',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: '6px',
                  }}
                >
                  <img src={SurveyResponseGif} />
                </div>
                <div style={{marginTop: '6px'}}>Email survey response view</div>
              </Card>
            </div>

            <div>
              <Card sectioned>
                <div
                  style={{
                    padding: '0px',
                    display: 'flex',
                    background: '#f5f5f5',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: '6px',
                  }}
                >
                  <img src={PeekOne} />
                </div>
                <div style={{marginTop: '6px'}}>
                  Running happiness scores and weekly trends
                </div>
              </Card>
            </div>

            <div>
              <Card sectioned>
                <div
                  style={{
                    padding: '0px',
                    display: 'flex',
                    background: '#f5f5f5',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: '6px',
                  }}
                >
                  <img src={PeekTwo} />
                </div>
                <div style={{marginTop: '6px'}}>
                  Department running happiness scores
                </div>
              </Card>
            </div>

            <div>
              <Card sectioned>
                <div
                  style={{
                    padding: '0px',
                    display: 'flex',
                    background: '#f5f5f5',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: '6px',
                  }}
                >
                  <img src={PeekThree} />
                </div>
                <div style={{marginTop: '6px'}}>Team comments board</div>
              </Card>
            </div>

            {/* <div>
             
              <Button
                onClick={() => {
                  window.location.href = "/new-company";
                }}
                size="large"
              >
                Get Started
              </Button>
            </div> */}
          </div>
        </div>
      </div>

      <div className="feature-container green">
        <div className="container">
          <div className="cta-container" data-aos="fade-right">
            <div>
              <h4>Start gaining insight into your team's well-being</h4>
              <Button
                onClick={() => {
                  window.location.href = '/new-company';
                }}
                size="large"
              >
                Get Started
              </Button>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <div id="pricing" className="feature-container">
        <div className="container" data-aos="fade-up">
          <div className="feature-grid">
            <label>
              <span>Flexible and affordable</span>
            </label>
          </div>

          <div className="feature-grid-content">
            <h2>Join today and save! 🎁</h2>

            <p style={{margin: 0, marginBottom: '24px', fontSize: '18px'}}>
              <span style={{fontSize: '24px'}}></span>
              For a limited time while we're in beta, we're offering all early
              users a pay-only-once, lifetime deal to Happy Team. <br /> For{' '}
              <b>
                $49.00 you'll get access to all PRO features today, and all new
                ones in the future.
              </b>{' '}
              Forever. Pretty sweet right?
            </p>
            <div className="pricing-container">
              <div>
                <Card title="Pro" sectioned>
                  <h2>
                    $49<small>/ one-time payment</small>
                  </h2>
                  <ul>
                    <li>1000 team seats </li>
                    <li>Access to all reports</li>
                    <li>Unlimited survey sends</li>
                    <li>Custom branding and email content</li>
                  </ul>
                  <Button
                    onClick={() => {
                      window.location.href = '/new-company';
                    }}
                    fullWidth
                  >
                    Get started
                  </Button>
                </Card>
              </div>
              <div style={{position: 'relative', opacity: 0.4}}>
                <Card title="Growth" sectioned>
                  <h2>
                    $25<small>/ month</small>
                  </h2>
                  <ul>
                    <li>500 team seats </li>
                    <li>Access to all reports</li>
                    <li>Unlimited survey sends</li>
                  </ul>
                  <Button
                    onClick={() => {
                      window.location.href = '/new-company';
                    }}
                    fullWidth
                    disabled
                  >
                    Get started
                  </Button>
                </Card>
              </div>
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  opacity: '0.4',
                }}
              >
                <Card title="Starter" sectioned>
                  <h2>
                    $10<small>/ month</small>
                  </h2>
                  <ul>
                    <li>100 team seats </li>
                    <li>Access to all reports</li>
                    <li>Send 1 weekly survey </li>
                  </ul>
                  <Button
                    onClick={() => {
                      window.location.href = '/new-company';
                    }}
                    fullWidth
                    disabled
                  >
                    Get started
                  </Button>
                </Card>
              </div>
            </div>
          </div>

          <div
            className="flex-row"
            style={{
              justifyContent: 'center',
              marginTop: '64px',
              flexDirection: 'column',
            }}
          >
            <h4 style={{fontSize: '16px', fontWeight: 'bold'}}>
              Need more seats or customizations?
            </h4>
            <a
              href="mailto:hey@gethappyteam.com"
              style={{color: '#3b7b6e', fontSize: '16px'}}
            >
              Contact us for enterprise plan details
            </a>
          </div>
        </div>
      </div>

      <div id="roadmap" className="feature-container white">
        <div className="container" data-aos="fade-up">
          <div className="feature-grid">
            <label>
              <span>ROADMAP</span>
            </label>
          </div>
          <div className="feature-grid-content">
            <h2>What we're planning</h2>
            <div
              className="flex-row"
              style={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginTop: '32px',
                flexDirection: 'column',
              }}
            >
              <h4 style={{fontSize: '16px', fontWeight: 'bold'}}>
                Have a feature or improvement you'd like to see?
              </h4>
              <a
                href="mailto:hey@gethappyteam.com"
                style={{color: '#3b7b6e', fontSize: '16px'}}
              >
                Let us know!
              </a>
            </div>
            <div className="roadmap">
              <div className="plan">
                <span className="bullet">1</span>
                <div className="plan-content">
                  <h3>Launch beta </h3>
                  <p>
                    🚀 HappyTeam is revealed to the world! Our goal for the next
                    month will be to collect as much feedback as we can and
                    support onboarding our initial customers. We'll use this
                    feedback to address any issues that arise, and plan new
                    features and improvements.
                  </p>
                  <Badge progress="complete" status="success">
                    In Production
                  </Badge>
                </div>
              </div>

              <div className="plan">
                <span className="bullet">2</span>
                <div className="plan-content">
                  <h3>Team Categories and Roles </h3>
                  <p>
                    With the introduction of team categories, you'll be able to
                    group responses by individual teams, giving you even deeper
                    insights. You'll be able to assign multiple roles to
                    individuals so they can view data for a tag or category they
                    belong to.
                  </p>
                  <Badge progress="partiallyComplete" status="info">
                    In Development
                  </Badge>
                </div>
              </div>

              <div className="plan">
                <span className="bullet">3</span>
                <div className="plan-content">
                  <h3>Custom Survey Questions </h3>
                  <p>
                    If you'd like to start asking your team members specific
                    questions on a specific day, or for a one-off use case, now
                    you can! We'll be building a question bank you can add and
                    include for any of your survey emails.
                  </p>
                  <Badge progress="incomplete" status="warning">
                    In Planning
                  </Badge>
                </div>
              </div>

              <div className="plan">
                <span className="bullet">4</span>
                <div className="plan-content">
                  <h3>Custom Graphs and Reporting </h3>
                  <p>
                    With custom reporting, you'll be able to control exactly the
                    data you want to see. Generate reports with advanced filters
                    and export to CSV and PDF.
                  </p>
                  <Badge progress="incomplete" status="new">
                    Not started
                  </Badge>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="feature-container white" style={{padding: '64px 8px'}}>
        <div className="footer">
          <img src={BrandMark} />
          <div
            className="flex-row"
            style={{color: 'lightgrey', marginTop: '12px'}}
          >
            &copy; {new Date().getFullYear()}. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingPage;
