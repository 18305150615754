const YTDReport = ({ dates }) => {
  let tally1 = 0; // great
  let tally2 = 0; // good
  let tally3 = 0; // meh
  let tally4 = 0; // not good
  let tally5 = 0; // angry

  Object.keys(dates).map((key) => {
    const date = dates[key];

    if (date.hasOwnProperty("1")) {
      tally1 += date["1"];
    }

    if (date.hasOwnProperty("2")) {
      tally2 += date["2"];
    }

    if (date.hasOwnProperty("3")) {
      tally3 += date["3"];
    }

    if (date.hasOwnProperty("4")) {
      tally4 += date["4"];
    }

    if (date.hasOwnProperty("5")) {
      tally5 += date["5"];
    }
  });

  const TOTAL_VOTES = tally1 + tally2 + tally3 + tally4 + tally5;

  const ytdChartData = {
    TOTAL_VOTES,
    GREAT: ((tally1 / TOTAL_VOTES) * 100).toFixed(2),
    GOOD: ((tally2 / TOTAL_VOTES) * 100).toFixed(2),
    NEUTRAL: ((tally3 / TOTAL_VOTES) * 100).toFixed(2),
    NOT_GOOD: ((tally4 / TOTAL_VOTES) * 100).toFixed(2),
    ANGRY: ((tally5 / TOTAL_VOTES) * 100).toFixed(2),
  };

 

  return ytdChartData;
};

export default YTDReport;
