import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

import { Navigation, Icon, IconSource } from "@shopify/polaris";

import {
  AnalyticsMajor,
  SettingsMajor,
  VocabularyMajor,
  NavigationMajor,
} from "@shopify/polaris-icons";

import { useHappyTeam } from "../../state";

const Nav = () => {
  const {
    state: { member_uid, member_role },
  } = useHappyTeam();

  const router = useLocation();

  const pathName = router.pathname;

  let items = [];

  if (member_uid === "") {
    items.push({
      url: "/new-company",
      label: "New Company",
    });

    items.push({
      url: "/sign-in",
      label: "Sign In",
    });
  } else {
    items.push({
      url: "/dashboard",
      label: "Team Dashboard",
      selected: pathName === "/dashboard",
      icon: AnalyticsMajor,
    });

    if (member_role === "ADMIN") {
      items.push({
        url: "/company",
        label: "Company Settings",
        selected: pathName === "/company",
        icon: SettingsMajor,
      });
    }
  }

  return (
    <Navigation location="/">
      <Navigation.Section items={items} />
      {member_uid !== "" && (
        <Navigation.Section
          separator
          items={[
            {
              url: "/kb",
              label: "Knowledgebase",
              selected: pathName === "/kb",
              icon: VocabularyMajor,
            },
            {
              url: "/roadmap",
              label: "Roadmap",
              selected: pathName === "/roadmap",
              icon: NavigationMajor,
            },
          ]}
        ></Navigation.Section>
      )}
    </Navigation>
  );
};

export default Nav;
