import * as React from 'react'

import {Page, Card, Layout, Badge} from '@shopify/polaris'

import {ChatMajor} from '@shopify/polaris-icons'

const KnowledgeBase = () => {
  return (
    <Page
      title="Roadmap"
      breadcrumbs={[{content: 'Dashboard', url: '/dashboard'}]}
      actionGroups={[
        {
          title: 'Need help?',
          actions: [
            {
              content: 'Contact support',
              onAction: () => {},
              icon: ChatMajor,
            },
          ],
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <Card.Section>
              <div
                id="roadmap"
                className="feature-container white"
                style={{padding: 0}}
              >
                <div className="container" data-aos="fade-up">
                  <div className="feature-grid-content">
                    <h3 style={{fontSize: '32px'}}>What we're planning</h3>
                    <div
                      className="flex-row"
                      style={{
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: '32px',
                        flexDirection: 'column',
                      }}
                    >
                      <h4 style={{fontSize: '16px', fontWeight: 'bold'}}>
                        Have a feature or improvement you'd like to see?
                      </h4>
                      <a
                        href="mailto:hey@gethappyteam.com"
                        style={{color: '#3b7b6e', fontSize: '16px'}}
                      >
                        Let us know!
                      </a>
                    </div>
                    <div className="roadmap">
                      <div className="plan">
                        <span className="bullet">1</span>
                        <div className="plan-content">
                          <h3>September 2021 – Launch beta </h3>
                          <p>
                            🚀 HappyTeam is revealed to the world! Our goal for
                            the next month will be to collect as much feedback
                            as we can and support onboarding our initial
                            customers. We'll use this feedback to address any
                            issues that arise, and plan new features and
                            improvements.
                          </p>
                          <Badge progress="complete" status="success">
                            In Production
                          </Badge>
                        </div>
                      </div>

                      <div className="plan">
                        <span className="bullet">3</span>
                        <div className="plan-content">
                          <h3>Custom Survey Questions </h3>
                          <p>
                            If you'd like to start asking your team members
                            specific questions on a specific day, or for a
                            one-off use case, now you can! We'll be building a
                            question bank you can add and include for any of
                            your survey emails.
                          </p>
                          <Badge progress="partiallyComplete" status="info">
                            In Progress
                          </Badge>
                        </div>
                      </div>

                      <div className="plan">
                        <span className="bullet">2</span>
                        <div className="plan-content">
                          <h3>Custom Graphs and Reporting </h3>
                          <p>
                            With custom reporting, you'll be able to control
                            exactly the data you want to see. Generate reports
                            with advanced filters and export to CSV and PDF.
                          </p>
                          <Badge progress="incomplete" status="new">
                            Not started
                          </Badge>
                        </div>
                      </div>

                      <div className="plan">
                        <span className="bullet">4</span>
                        <div className="plan-content">
                          <h3>Team Categories and Roles </h3>
                          <p>
                            With the introduction of team categories, you'll be
                            able to group responses by individual teams, giving
                            you even deeper insights. You'll be able to assign
                            multiple roles to individuals so they can view data
                            for a tag or category they belong to.
                          </p>
                          <Badge progress="incomplete" status="new">
                            Not started
                          </Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default KnowledgeBase
