import * as React from "react";
import keys from "ramda/src/keys";
import sort from "ramda/src/sort";

import CsvDownload from "react-json-to-csv";
import { Bar, Radar, Line } from "react-chartjs-2";
import { Progress } from "antd";

import { format, fromUnixTime } from "date-fns";

import { useHappyTeam } from "../../state/index";
import { db } from "../../api/db-config/index";

import tagReport from "./reports/tag-report";
import YTDReport from "./reports/ytd-report";
import trendsOverTimeReport from "./reports/trends-over-time-report";

import AdminDashboard from "../admin/index";

import Comments from "../../components/comments";

import { VocabularyMajor, ChatMajor } from "@shopify/polaris-icons";

import {
  Layout,
  Card,
  Stack,
  EmptyState,
  Page,
  SkeletonBodyText,
} from "@shopify/polaris";

const CompanyDashboard = () => {
  const [loading, setLoading] = React.useState(true);

  const [tagLabels, setTagLabels] = React.useState([]);
  const [tagData, setTagData] = React.useState([]);

  const [ytdReportData, setYtdReportData] = React.useState({});
  const [dailyReportData, setDailyReportData] = React.useState({});

  const {
    state: { company_id, member_role },
  } = useHappyTeam();

  const fetchComments = async () => {
    // grab survey data.
    const companySurveyRef = db.collection("surveys").doc(company_id);

    companySurveyRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const docData = doc.data();

          console.log("SURVEY DOC DATA", docData);

          if (docData.hasOwnProperty("tags")) {
            console.log("HIT INSIDE TAGS");

            const TAGS = docData.tags;

            if (TAGS.hasOwnProperty("ADMIN")) {
              console.log("HIT INSIDE ADMIN");
              delete TAGS.ADMIN;
            }

            const tagLabelsToSet = Object.keys(TAGS).map((tag) => tag);
            setTagLabels(tagLabelsToSet);
            const tagReportData = tagReport({ tags: TAGS });
            setTagData(tagReportData);
          }

          let ytdReportDataFormatted = { ...docData };

          console.log("YTD OBJ", ytdReportDataFormatted);

          if (ytdReportDataFormatted.hasOwnProperty("comments")) {
            delete ytdReportDataFormatted.comments;
          }

          if (ytdReportDataFormatted.hasOwnProperty("tags")) {
            delete ytdReportDataFormatted.tags;
          }

          const YTDReportResponse = YTDReport({
            dates: ytdReportDataFormatted,
          });
          setYtdReportData(YTDReportResponse);

          const trendsOverTimeReportResponse = trendsOverTimeReport({
            dates: ytdReportDataFormatted,
          });

          setDailyReportData(trendsOverTimeReportResponse);

          setLoading(false);
        } else {
          console.log("NO DATA");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error fetching dashboard data", error);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchComments();
  }, []);

  const tagChartData = {
    labels: tagLabels,
    datasets: tagData,
  };

  if (member_role === "HAPPY_TEAM") {
    return <AdminDashboard />;
  }

  return (
    <Page
      title={`Team Dashboard`}
      actionGroups={[
        {
          title: "Need help?",
          actions: [
            {
              content: "View docs",
              onAction: () => {
                window.location.href = "/kb";
              },
              icon: VocabularyMajor,
            },
            {
              content: "Contact support",
              onAction: () => {},
              icon: ChatMajor,
            },
          ],
        },
      ]}
    >
      <div style={{ marginTop: "24px" }}>
        <Layout>
          <Layout.Section>
            <Card
              title="Running Happiness Scores"
              sectioned
              actions={[
                {
                  content: "Learn more",
                  onAction: () => (window.location.href = "/kb"),
                },
              ]}
            >
              {loading ? (
                <SkeletonBodyText lines={4} />
              ) : Object.keys(ytdReportData).length > 0 ? (
                <div data-aos="fade-left">
                  <Stack alignment="center" distribution="center">
                    <Card sectioned>
                      <div>
                        <Progress
                          type="circle"
                          percent={ytdReportData.GREAT}
                          format={(percent) => `🤗`}
                          strokeColor={{
                            "100%": "#aee9d0",
                          }}
                        />
                      </div>
                      <div className="mood-score">
                        <div style={{ fontWeight: "600" }}>
                          {ytdReportData.GREAT}%
                        </div>

                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#928282",
                            letterSpacing: "1px",
                          }}
                        >
                          GREAT
                        </div>
                      </div>
                    </Card>

                    <Card sectioned>
                      <Progress
                        type="circle"
                        percent={ytdReportData.GOOD}
                        format={(percent) => `🙂`}
                        strokeColor={{
                          "100%": "#a5e7f2",
                        }}
                      />
                      <div className="mood-score">
                        <div style={{ fontWeight: "600" }}>
                          {ytdReportData.GOOD}%
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#928282",
                            letterSpacing: "1px",
                          }}
                        >
                          GOOD
                        </div>
                      </div>
                    </Card>

                    <Card sectioned>
                      <Progress
                        type="circle"
                        percent={ytdReportData.NEUTRAL}
                        format={(percent) => `😐`}
                        strokeColor={{
                          "100%": "#e4e5e7",
                        }}
                      />
                      <div className="mood-score">
                        <div style={{ fontWeight: "600" }}>
                          {ytdReportData.NEUTRAL}%
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#928282",
                            letterSpacing: "1px",
                          }}
                        >
                          NEUTRAL
                        </div>
                      </div>
                    </Card>

                    <Card sectioned>
                      <Progress
                        type="circle"
                        percent={ytdReportData.NOT_GOOD}
                        format={(percent) => `😒`}
                        strokeColor={{
                          "100%": "#ffd79d",
                        }}
                      />
                      <div className="mood-score">
                        <div style={{ fontWeight: "600" }}>
                          {ytdReportData.NOT_GOOD}%
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#928282",
                            letterSpacing: "1px",
                          }}
                        >
                          NOT GOOD
                        </div>
                      </div>
                    </Card>

                    <Card sectioned>
                      <Progress
                        type="circle"
                        percent={ytdReportData.ANGRY}
                        format={(percent) => `🤬`}
                        strokeColor={{
                          "100%": "#fed3d1",
                        }}
                      />

                      <div className="mood-score">
                        <div style={{ fontWeight: "600" }}>
                          {ytdReportData.ANGRY}%
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#928282",
                            letterSpacing: "1px",
                          }}
                        >
                          ANGRY
                        </div>
                      </div>
                    </Card>
                  </Stack>
                </div>
              ) : (
                <EmptyState
                  heading="No survey data to display"
                  image="https://storage.googleapis.com/happy-team-de21b.appspot.com/empty-state.png"
                  secondaryAction={{
                    content: "Invite your team",
                    url: "/company",
                  }}
                ></EmptyState>
              )}
            </Card>

            <Card
              title="7-day Happiness Trend"
              sectioned
              actions={[
                {
                  content: "Learn more",
                  onAction: () => (window.location.href = "/kb"),
                },
              ]}
            >
              {loading ? (
                <SkeletonBodyText lines={10} />
              ) : Object.keys(dailyReportData).length > 0 ? (
                <Line data={dailyReportData} />
              ) : (
                <EmptyState
                  heading="No survey data to display"
                  image="https://storage.googleapis.com/happy-team-de21b.appspot.com/empty-state.png"
                  secondaryAction={{
                    content: "Invite your team",
                    url: "/company",
                  }}
                ></EmptyState>
              )}
            </Card>

            <Card
              title="Happiness by Tag"
              sectioned
              actions={[
                {
                  content: "Learn more",
                  onAction: () => (window.location.href = "/kb"),
                },
              ]}
            >
              {loading ? (
                <SkeletonBodyText lines={10} />
              ) : tagChartData.datasets.length > 0 ? (
                <Bar data={tagChartData} />
              ) : (
                <EmptyState
                  heading="No survey data to display"
                  image="https://storage.googleapis.com/happy-team-de21b.appspot.com/empty-state.png"
                  secondaryAction={{
                    content: "Invite your team",
                    url: "/company",
                  }}
                ></EmptyState>
              )}
            </Card>

            <Comments />
          </Layout.Section>
        </Layout>
      </div>
    </Page>
  );
};

export default CompanyDashboard;
