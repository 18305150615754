import * as React from "react";

import { Page, Card, Layout } from "@shopify/polaris";

import { ChatMajor } from "@shopify/polaris-icons";

const KnowledgeBase = () => {
  return (
    <Page
      title="Knowledgebase"
      breadcrumbs={[{ content: "Dashboard", url: "/dashboard" }]}
      actionGroups={[
        {
          title: "Need help?",
          actions: [
            {
              content: "Contact support",
              onAction: () => {},
              icon: ChatMajor,
            },
          ],
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Card title="Surveys" sectioned>
            <Card.Section>
              <h4>
                <b>When are surveys sent?</b>
              </h4>
              <p>
                Depending on your plan type, you can select one or multiple days
                you'd like to send out your team happiness surveys. In the
                future, we will be enabling "time to send", but currently all
                emails get sent at 11am EST.
              </p>
            </Card.Section>
            <Card.Section>
              <h4>
                <b>Survey responses</b>
              </h4>
              <p>
                When a team member is sent a survey, they are given the option
                to respond with a 🤗, 🙂, 😐, 😒, or 🤬 emoji. Once submitted,
                our service will prompt the team member to leave a comment,
                which is totally optional. All responses are 100% anonymous – we
                don't even keep a record of who responded in our database.
              </p>
            </Card.Section>
          </Card>

          <Card title="Team dashboard" sectioned>
            <Card.Section>
              <h4>
                <b>What is the "Running Happiness Score" report?</b>
              </h4>
              <p>
                This report is showing you the accumulative happiness
                percentages for your team, grouped by survey response. It
                accounts for every single survey submitted since your team
                started to use the HappyTeam product. You can use this report to
                determine the overall sentiment and feeling of team members in
                your company.
              </p>
            </Card.Section>

            <Card.Section>
              <h4>
                <b>What is the "7-day Happiness Trend" report?</b>
              </h4>
              <p>
                This report shows the number of survey responses your team has
                submitted, as well as the response that was given over the last
                7 days. You can use this report to get a quick view of how the
                team is recently feeling.
              </p>
            </Card.Section>

            <Card.Section>
              <h4>
                <b>What is the "Department Overall Happiness" report?</b>
              </h4>
              <p>
                Similiar to the "Running Happiness Score" report, the
                Depeartment Overall Happiness report gives you insight into the
                accumulative happiness per department (set up as tags).
              </p>
            </Card.Section>

            <Card.Section>
              <h4>
                <b>Comments</b>
              </h4>
              <p>
                Comments appear in your dashboard after a team member has added
                one via their survey response. They display by "most recent",
                and members can upvote if they wish.
              </p>
            </Card.Section>
          </Card>

          <Card title="Organization settings" sectioned>
            <Card.Section>
              <h4>
                <b>Email send dates</b>
              </h4>
              <p>
                Depending on your plan type, you can select one or multiple days
                you'd like to send out your team happiness surveys. In the
                future, we will be enabling "time to send", but currently all
                emails get sent at 11am EST.
              </p>
            </Card.Section>

            <Card.Section>
              <h4>
                <b>Tags</b>
              </h4>
              <p>
                Creating tags will allow you to assign them to team members -
                which are then used to group their survey responses. We label
                "tags" as "Department" in the dashboard, but you are free to use
                them to group however you see fit.
              </p>
            </Card.Section>

            <Card.Section>
              <h4>
                <b>Branding</b>
              </h4>
              <p>
                Pro plan accounts are given the option to have their survey
                emails match their company brand. You can add your logo, primary
                color, and a few sentences of custom content to appear in the
                email.
              </p>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default KnowledgeBase;
