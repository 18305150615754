import * as React from "react";

import { db, auth } from "../../../api/db-config/index";
import { useHappyTeam } from "../../../state/index";

import WordMark from "../../../assets/logo-new.png";

import {
  Page,
  TextField,
  Layout,
  Card,
  Button,
  FormLayout,
  DisplayText,
} from "@shopify/polaris";

const SignIn = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const { dispatch } = useHappyTeam();

  const handleSignIn = () => {
    setError("");

    // 1. login with email and password
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        // 2. query user
        const userDoc = db.collection("users").doc(user.uid);

        userDoc
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log("User document data:", doc.data());

              const userData = doc.data();
              // const { company_id, email, name, role, user_id, tag } = userData;
              let { company_id, email, name, role, user_id, tag } = userData;

              dispatch({ type: "setCompanyID", payload: company_id });
              dispatch({ type: "setMemberUid", payload: user_id });
              dispatch({ type: "setMemberEmail", payload: email });
              dispatch({ type: "setMemberRole", payload: role });
              dispatch({ type: "setMemberName", payload: name });
              dispatch({ type: "setMemberTag", payload: tag });

              // 4. query company details and set into state
              const companyDoc = db.collection("company").doc(company_id);

              companyDoc
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    const companyData = doc.data();

                    const {
                      admin_email,
                      admin_user_id,
                      company_name,
                      company_send_dates,
                      company_tags,
                      company_team_members,
                      plan,
                      invites = [],
                      custom_logo = false,
                      custom_email_content = false,
                      custom_color = false,
                    } = companyData;

                    dispatch({ type: "setCompanyPlan", payload: plan });
                    dispatch({ type: "setCompanyName", payload: company_name });
                    dispatch({ type: "setInvites", payload: invites });
                    dispatch({
                      type: "setCompanyAdminEmail",
                      payload: admin_email,
                    });
                    dispatch({
                      type: "setCompanyAdminId",
                      payload: admin_user_id,
                    });
                    dispatch({
                      type: "setCompanySendDates",
                      payload: company_send_dates,
                    });
                    dispatch({ type: "setCompanyTags", payload: company_tags });
                    dispatch({
                      type: "setCompanyTeamMembers",
                      payload: company_team_members,
                    });

                    dispatch({ type: "setCompanyLogo", payload: custom_logo });
                    dispatch({
                      type: "setCustomEmailContent",
                      payload: custom_email_content,
                    });

                    dispatch({ type: "setCustomColor", payload: custom_color });

                    // 5. redirect to company dashboard
                    window.location.href = "/dashboard";
                  } else {
                    // doc.data() will be undefined in this case
                    setError("Could not signin user - please contact support");
                  }
                })
                .catch((error) => {
                  setError("Could not signin user - please contact support");
                });
            } else {
              // doc.data() will be undefined in this case
              setError("Could not signin user - please contact support");
            }
          })
          .catch((error) => {
            setError("Could not signin user - please contact support");
          });
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <div style={{ marginBottom: "48px" }}>
              <DisplayText size="large">Sign In</DisplayText>
            </div>

            <FormLayout>
              <TextField
                type="text"
                label="Email"
                onChange={(value) => setEmail(value)}
                value={email}
                requiredIndicator
              />
              <TextField
                type="password"
                label="Password"
                onChange={(value) => setPassword(value)}
                value={password}
                requiredIndicator
              />
              <Button primary onClick={handleSignIn}>
                Sign In
              </Button>

              {error && <div style={{ color: "red" }}>{error}</div>}
            </FormLayout>
          </Card>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "18px",
            }}
          >
            <img src={WordMark} style={{ height: 22, width: "auto" }} />
          </div>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default SignIn;
