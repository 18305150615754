import { useHappyTeam } from "../../state";

import { Page, Card, EmptyState } from "@shopify/polaris";

const AuthRoute = ({ children, adminOnly, happyTeamAdmin }) => {
  const {
    state: { member_role, member_uid },
  } = useHappyTeam();

  if (
    (adminOnly && member_role !== "ADMIN") ||
    member_uid === "" ||
    (happyTeamAdmin && member_role !== "HAPPY_TEAM")
  ) {
    return (
      <Page>
        <Card sectioned>
          <EmptyState
            heading="Page unavailable"
            action={{
              content: "Sign In",
              onAction: () => (window.location.href = "/sign-in"),
            }}
            secondaryAction={{
              content: "Contact support",
              url: "mailto:hey@gethappyteam.com",
            }}
            image="https://storage.googleapis.com/happy-team-de21b.appspot.com/2676383.jpg"
          >
            <p>
              Sorry about this! You've either landed a page that no longer
              exists, or requires you to be signed in.
            </p>
          </EmptyState>
        </Card>
      </Page>
    );
  }

  return children;
};

export default AuthRoute;
