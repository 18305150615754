import * as React from "react";

const APP_STATE = {
  key: "GET_HAPPY_TEAM",
  company_id: "",
  company_name: "",
  admin_email: "",
  admin_user_id: "",
  company_send_dates: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
  },
  invites: [],
  company_team_members: [],
  company_tags: [],
  company_plan: "",
  member_name: "",
  member_email: "",
  member_timezone: "",
  member_role: "UNKNOWN", // UNKNOWN, EMPLOYEE, ADMIN
  member_uid: "",
  member_tag: "",
  custom_logo: false,
  custom_email_content: false,
  custom_color: false,
};

const HappyTeamContext = React.createContext();

function happyTeamReducer(state, action) {
  switch (action.type) {
    case "setCompanyID": {
      return { ...state, company_id: action.payload };
    }
    case "setCompanyName": {
      return { ...state, company_name: action.payload };
    }
    case "setInvites": {
      return { ...state, invites: action.payload };
    }
    case "setCompanyPlan": {
      return { ...state, company_plan: action.payload };
    }
    case "setCompanyAdminEmail": {
      return { ...state, admin_email: action.payload };
    }
    case "setCompanyAdminId": {
      return { ...state, admin_user_id: action.payload };
    }
    case "setCompanySendDates": {
      return { ...state, company_send_dates: action.payload };
    }
    case "setCompanyTags": {
      return { ...state, company_tags: action.payload };
    }
    case "setCompanyTeamMembers": {
      return { ...state, company_team_members: action.payload };
    }

    case "setMemberName": {
      return { ...state, member_name: action.payload };
    }

    case "setMemberTag": {
      return { ...state, member_tag: action.payload };
    }

    case "setMemberEmail": {
      return { ...state, member_email: action.payload };
    }

    case "setMemberTimeZone": {
      return { ...state, member_timezone: action.payload };
    }

    case "setMemberRole": {
      return { ...state, member_role: action.payload };
    }

    case "setMemberUid": {
      return { ...state, member_uid: action.payload };
    }

    case "setCompanyLogo": {
      return { ...state, custom_logo: action.payload };
    }

    case "setCustomEmailContent": {
      return { ...state, custom_email_content: action.payload };
    }

    case "setCustomColor": {
      return { ...state, custom_color: action.payload };
    }

    case "setSignOut": {
      return { ...APP_STATE };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function HappyTeamProvider({ children }) {
  const getInitialState = () => {
    const savedState = window.localStorage.getItem(APP_STATE.key);

    try {
      if (!savedState) {
        return { ...APP_STATE };
      }
      return JSON.parse(savedState ?? { ...APP_STATE });
    } catch (e) {
      console.error("Error loading state");
      return { ...APP_STATE };
    }
  };

  const [state, dispatch] = React.useReducer(
    happyTeamReducer,
    getInitialState()
  );

  const value = { state, dispatch };

  React.useEffect(() => {
    window.localStorage.setItem(APP_STATE.key, JSON.stringify(state));
  }, [state]);

  return (
    <HappyTeamContext.Provider value={value}>
      {children}
    </HappyTeamContext.Provider>
  );
}

function useHappyTeam() {
  const context = React.useContext(HappyTeamContext);
  if (context === undefined) {
    throw new Error("useHappyTeam must be used within a Provider");
  }
  return context;
}

export { HappyTeamProvider, useHappyTeam };
