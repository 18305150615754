const tagReport = ({ tags }) => {
  let RATING_TYPES = [
    {
      label: "🤗 Great!",
      data: [],
      backgroundColor: "#20bf6b",
      value: 1,
    },
    {
      label: "🙂 Good",
      data: [],
      backgroundColor: "#45aaf2",
      value: 2,
    },
    {
      label: "😐 Neutral",
      data: [],
      backgroundColor: "#a5b1c2",
      value: 3,
    },
    {
      label: "😒 Not good",
      data: [],
      backgroundColor: "#f7b731",
      value: 4,
    },
    {
      label: "🤬 Angry",
      data: [],
      backgroundColor: "#fc5c65",
      value: 5,
    },
  ];

  RATING_TYPES.map((ratingType) => {
    let cpyRatingType = { ...ratingType };

    console.log("TAGS", tags)

    Object.keys(tags).map((tag) => {
      const currentRatingTypeValue = ratingType.value;

      if (tags[tag].hasOwnProperty(currentRatingTypeValue)) {
        cpyRatingType.data.push(tags[tag][currentRatingTypeValue]);
      } else {
        cpyRatingType.data.push(0);
      }
    });

    return cpyRatingType;
  });

  return RATING_TYPES;
};

export default tagReport;
