import * as React from 'react'
import './App.css'
import LogRocket from 'logrocket'

import 'antd/dist/antd.css'

import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'

import enTranslations from '@shopify/polaris/locales/en.json'
import {AppProvider, Page, Card, EmptyState} from '@shopify/polaris'

import {useHappyTeam} from './state'

import CompanyProfile from './pages/company-settings/index'
import Survey from './pages/survey/index'
import CompanyFeed from './pages/company-dashboard/index'
import KnowledgeBase from './pages/knowledgebase'
import Roadmap from './pages/roadmap'

import CompanySignup from './pages/auth/company_signup/index'
import SignIn from './pages/auth/signin/index'
import JoinCompany from './pages/join-company'

import TermsOfService from './pages/terms/terms'
import MarketingPage from './pages/marketing'
import HappyTeamAdmin from './pages/admin'

import AuthRoute from './components/auth-route/index'
import {Frame} from '@shopify/polaris'

import Nav from './components/nav'
import TopBar from './components/nav/top-bar'

const navMarkup = <Nav />
const topBarMarkup = <TopBar />

export default function App() {
  const {
    state: {company_id, member_name, member_email, company_plan, member_uid},
  } = useHappyTeam()

  React.useEffect(() => {
    LogRocket.init('38rovs/happyteam')

    LogRocket.identify(member_uid, {
      name: member_name,
      email: member_email,
      subscriptionType: company_plan,
    })
  }, [company_id, member_name, member_email, company_plan, member_uid])

  const theme = {
    logo: {
      width: 124,
      topBarSource:
        'https://storage.googleapis.com/happy-team-de21b.appspot.com/logo-new.png',
      url: 'https://gethappyteam.com',
      accessibilityLabel: 'Happy Team',
    },
    colors: {
      surface: '#111213',
      onSurface: '#111213',
      interactive: '#58B19F',
      secondary: '#111213',
      primary: '#58B19F',
      critical: '#d82c0d',
      warning: '#ffc453',
      highlight: '#5bcdda',
      success: '#008060',
      decorative: '#ffc96b',
    },
  }

  const InternalPage = ({children}) => {
    return (
      <Frame navigation={navMarkup} topBar={topBarMarkup}>
        {children}
      </Frame>
    )
  }

  const ExternalPage = ({children, title}) => {
    return <Page title={title}>{children}</Page>
  }

  return (
    <AppProvider i18n={enTranslations} theme={theme}>
      <Router>
        <div>
          <Switch>
            <Route path="/sign-in">
              <ExternalPage>
                <SignIn />
              </ExternalPage>
            </Route>
            <Route path="/new-company/:email_address">
              <ExternalPage>
                <CompanySignup />
              </ExternalPage>
            </Route>
            <Route path="/new-company">
              <ExternalPage>
                <CompanySignup />
              </ExternalPage>
            </Route>

            <Route path="/survey/:company/:tag/:mood">
              <ExternalPage title=" ">
                <Survey />
              </ExternalPage>
            </Route>
            <Route path="/join/:company_id/:invite_id/:company/:name/:email/:tag">
              <ExternalPage path="Join your team">
                <JoinCompany />
              </ExternalPage>
            </Route>

            <Route path="/dashboard">
              <AuthRoute>
                <InternalPage>
                  <CompanyFeed />
                </InternalPage>
              </AuthRoute>
            </Route>

            <Route exact path="/company">
              <AuthRoute adminOnly>
                <InternalPage>
                  <CompanyProfile />
                </InternalPage>
              </AuthRoute>
            </Route>

            <Route exact path="/happy-admin">
              <AuthRoute happyTeamAdmin>
                <InternalPage>
                  <HappyTeamAdmin />
                </InternalPage>
              </AuthRoute>
            </Route>

            <Route path="/kb">
              <AuthRoute>
                <InternalPage>
                  <KnowledgeBase />
                </InternalPage>
              </AuthRoute>
            </Route>

            <Route path="/roadmap">
              <AuthRoute>
                <InternalPage>
                  <Roadmap />
                </InternalPage>
              </AuthRoute>
            </Route>

            <Route exact path="/">
              <MarketingPage />
            </Route>

            <Route exact path="/terms">
              <TermsOfService />
            </Route>

            <Route path="/">
              <Page>
                <Card sectioned>
                  <EmptyState
                    heading="Page unavailable"
                    action={{
                      content: 'Sign In',
                      onAction: () => (window.location.href = '/sign-in'),
                    }}
                    secondaryAction={{
                      content: 'Contact support',
                      url: 'mailto:hey@gethappyteam.com',
                    }}
                    image="https://storage.googleapis.com/happy-team-de21b.appspot.com/2676383.jpg"
                  >
                    <p>
                      Sorry about this! You've either landed a page that no
                      longer exists, or requires you to be signed in.
                    </p>
                  </EmptyState>
                </Card>
              </Page>
            </Route>
          </Switch>
        </div>
      </Router>
    </AppProvider>
  )
}
