import * as React from "react";
import keys from "ramda/src/keys";
import sort from "ramda/src/sort";
import { format } from "date-fns";

import { useHappyTeam } from "../../state/index";
import { db } from "../../api/db-config";

import {
  Card,
  SkeletonBodyText,
  EmptyState,
  Button,
  Icon,
} from "@shopify/polaris";
import { ThumbsUpMajor } from "@shopify/polaris-icons";

const Comments = () => {
  const [loading, setLoading] = React.useState(true);

  const [comments, setComments] = React.useState({});
  const [commentKeys, setCommentKeys] = React.useState([]);

  const {
    state: { company_id },
  } = useHappyTeam();

  const diff = function (a, b) {
    return b - a;
  };

  const fetchComments = async () => {
    try {
      const dbRef = db.collection("surveys").doc(company_id);
      const doc = await dbRef.get();

      if (doc.exists) {
        const commentData = doc.data();

        if (commentData.hasOwnProperty("comments")) {
          setComments(commentData.comments);
          const sortedKeys = sort(diff, keys(commentData.comments));
          setCommentKeys(sortedKeys);
        }
      }
    } catch (error) {
      console.error("could not fetch comments");
    } finally {
      setLoading(false);
    }
  };

  const handleUpvote = (votes, commentId, theComment) => {
    db.collection("surveys")
      .doc(company_id)
      .update({
        comments: {
          ...comments,
          [commentId]: {
            votes: parseInt(votes) + 1,
            id: commentId,
            comment: theComment,
          },
        },
      })
      .then(() => {
        console.log("Document successfully updated!");
        fetchComments();
      });
  };

  React.useEffect(() => {
    fetchComments();
  }, []);

  if (loading) {
    return (
      <Card title="Comments" sectioned>
        <div>
          <div style={{ marginBottom: "24px" }}>
            <SkeletonBodyText lines={3} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <SkeletonBodyText lines={3} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <SkeletonBodyText lines={3} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <SkeletonBodyText lines={3} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <SkeletonBodyText lines={3} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <SkeletonBodyText lines={3} />
          </div>
        </div>
      </Card>
    );
  }

  if (!loading && commentKeys.length == 0) {
    return (
      <Card title="Comments" sectioned>
        <EmptyState
          heading="No comments yet"
          action={{ content: "Invite team", url: "/company" }}
          image="https://storage.googleapis.com/happy-team-de21b.appspot.com/empty-state.png"
        >
          <p>Comments will appear as your team responds to surveys</p>
        </EmptyState>
      </Card>
    );
  }

  return (
    <Card title="Comments" sectioned>
      {commentKeys.map((key) => {
        return (
          <div
            key={key}
            style={{
              borderBottom: "1px solid #f5f5f5",
              padding: "12px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <small style={{ color: "#222", fontSize: '14px',  }}>
                {" "}
                {format(parseInt(key), "MMM dd, yyyy")}
              </small>

              <Button
                onClick={() =>
                  handleUpvote(comments[key].votes, key, comments[key].comment)
                }
                size="slim"
                outline
              >
                <div
                  className="comment"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Icon color="success" source={ThumbsUpMajor} />{" "}
                  {comments[key].votes}
                </div>
              </Button>
            </div>

            <p style={{ fontSize: 16 }}>{comments[key].comment}</p>
          </div>
        );
      })}
    </Card>
  );
};

export default Comments;
