import * as React from "react";
import { useParams } from "react-router-dom";
import { format, subDays } from "date-fns";
import { db } from "../../api/db-config/index";

import {
  Page,
  Card,
  Layout,
  Stack,
  RadioButton,
  TextField,
  Button,
} from "@shopify/polaris";

import { ChatMajor } from "@shopify/polaris-icons";

const Survey = () => {
  const params = useParams();
  const { company, mood, tag } = params;

  const [selectedMood, setSelectedMood] = React.useState(mood);
  const [comment, setComment] = React.useState("");
  const [surveySubmitted, setSurveySubmitted] = React.useState(false);

  const member_tag = tag;

  const handleSubmit = () => {
    const previousDay = subDays(new Date(), 0);

    const today = format(previousDay, "yyyy-MM-dd");

    const companySurveyRef = db.collection("surveys").doc(company);

    const newCommentID = Date.now();

    companySurveyRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // check if the "today" exists as a key.
          const companySurveys = doc.data();

          if (companySurveys.hasOwnProperty(today)) {
            const todaysMood = doc.data()[today];
            const myMoodNewVal = todaysMood.hasOwnProperty(selectedMood)
              ? parseInt(todaysMood[selectedMood]) + 1
              : 1;

            db.collection("surveys")
              .doc(company)
              .update({
                [today]: {
                  ...todaysMood,
                  [selectedMood]: myMoodNewVal,
                },
              });
          } else {
            // create the new key for today
            db.collection("surveys")
              .doc(company)
              .set({
                ...companySurveys,
                [today]: {
                  [selectedMood]: 1,
                },
              });
          }

          // set survey results by member tag
          if (companySurveys.hasOwnProperty("tags")) {
            const allTags = companySurveys.tags;

            if (allTags.hasOwnProperty(member_tag)) {
              const memberTag = companySurveys.tags[member_tag];

              const updatedTagVal = memberTag.hasOwnProperty(selectedMood)
                ? parseInt(memberTag[selectedMood]) + 1
                : 1;

              db.collection("surveys")
                .doc(company)
                .update({
                  tags: {
                    ...allTags,
                    [member_tag]: {
                      ...memberTag,
                      [selectedMood]: updatedTagVal,
                    },
                  },
                });
            } else {
              // add a base prop
              db.collection("surveys")
                .doc(company)
                .update({
                  tags: {
                    ...allTags,
                    [member_tag]: {
                      [selectedMood]: 1,
                    },
                  },
                });
            }
          } else {
            // do tags even exist? if not.. add the first one.
            db.collection("surveys")
              .doc(company)
              .update({
                tags: {
                  [member_tag]: {
                    [selectedMood]: 1,
                  },
                },
              });
          }

          // update comments
          if (comment) {
            const allComments = companySurveys.comments || {};

            db.collection("surveys")
              .doc(company)
              .update({
                comments: {
                  ...allComments,
                  [newCommentID]: {
                    id: newCommentID,
                    comment: comment,
                    votes: 1,
                  },
                },
              });
          }
        } else {
          // no document, create the first one
          db.collection("surveys")
            .doc(company)
            .set({
              [today]: {
                [selectedMood]: 1,
              },
              tags: member_tag
                ? {
                    [member_tag]: {
                      [selectedMood]: 1,
                    },
                  }
                : {},
              comments: comment
                ? {
                    [newCommentID]: {
                      id: newCommentID,
                      comment: comment,
                      votes: 1,
                    },
                  }
                : {},
            });
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    setSurveySubmitted(true);
  };

  if (surveySubmitted) {
    return (
      <div className="survey-page">
        <Card title="Survey submitted!" sectioned>
          <p style={{ marginBottom: "12px", fontSize: "16px" }}>
            Thank you for your feedback! Your happiness score contributes to the
            average sentiment score of your company.
          </p>

          <Button
            onClick={() => {
              window.location.href = "/dashboard";
            }}
            primary
          >
            See how your team is doing
          </Button>
        </Card>
      </div>
    );
  }

  return (
    <div className="survey-page">
      <Page title={`${format(Date.now(), "MMM dd, yyyy")} Happiness Survey`}>
        <Card sectioned title="How are you feeling today?">
          <Stack vertical>
            <RadioButton
              label=" 🤗 Great!"
              id="great"
              type="radio"
              name="mood"
              value="1"
              checked={selectedMood === "1"}
              onChange={() => setSelectedMood("1")}
            />

            <RadioButton
              label="🙂 Good"
              id="good"
              type="radio"
              name="mood"
              value="2"
              checked={selectedMood === "2"}
              onChange={() => setSelectedMood("2")}
            />

            <RadioButton
              label="😐 Neutral"
              id="meh"
              type="radio"
              name="mood"
              value="3"
              checked={selectedMood === "3"}
              onChange={() => setSelectedMood("3")}
            />

            <RadioButton
              label="😒 Not good"
              id="not_good"
              type="radio"
              name="mood"
              value="4"
              checked={selectedMood === "4"}
              onChange={() => setSelectedMood("4")}
            />

            <RadioButton
              label="🤬 Angry"
              id="angry"
              type="radio"
              name="mood"
              value="5"
              checked={selectedMood === "5"}
              onChange={() => setSelectedMood("5")}
            />

            <div style={{ marginTop: "16px" }}>
              {" "}
              <TextField
                value={comment}
                onChange={(value) => setComment(value)}
                label="Care to share any additional thoughts?"
                multiline={3}
              />
            </div>
            <Button fullWidth primary onClick={handleSubmit}>
              Submit
            </Button>
          </Stack>
        </Card>
      </Page>
    </div>
  );
};

export default Survey;
