import * as React from "react";
import { useParams } from "react-router-dom";
import { db, auth } from "../../api/db-config/index";
import { useHappyTeam } from "../../state/index";

import WordMark from "../../assets/logo-new.png";

import {
  Page,
  TextField,
  Layout,
  Card,
  Button,
  FormLayout,
  DisplayText,
} from "@shopify/polaris";

const JoinCompany = () => {
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const params = useParams();
  const { company_id, invite_id, company, name, email, tag } = params;

  const { dispatch } = useHappyTeam();

  const fetchOrgData = () => {
    return db
      .collection("company")
      .doc(company_id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const companyData = doc.data();
          // setOrgData(companyData);
          return companyData;
        }
      });
  };

  const handleAccountCreation = async () => {
    // fetch latest org data
    const orgData = await fetchOrgData();

    auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const newUser = userCredential.user;
        const USER_ID = newUser.uid;

        /**
         *
         * create record under /USERS
         *
         * */

        db.collection("users")
          .doc(USER_ID)
          .set({
            company_id: company_id,
            user_id: USER_ID,
            name: name,
            email: email,
            role: "EMPLOYEE",
          })
          .catch((error) => {
            console.log("failed to create user", error);
            setError("Failed to create user");
            return;
          });

        /**
         *
         * update company/company_team_members to use newUser.uid
         *
         * -- filter out this user by invite id
         * -- add user back to all team members object with newUser.id and write to db
         * -- update the email send list and write to db
         */

        let companyTeamMembers = orgData.company_team_members.filter(
          (team_member) => team_member.id !== invite_id
        );

        const updatedUserRecord = {
          id: USER_ID,
          email: email,
          name: name,
          tag: tag,
        };

        companyTeamMembers = [...companyTeamMembers, updatedUserRecord];

        db.collection("company")
          .doc(company_id)
          .update({
            company_team_members: companyTeamMembers,
          })
          .then(() => {
            let allTeamMembersEmailList = {};
            companyTeamMembers.map((team_member) => {
              allTeamMembersEmailList[`${team_member.id}`] = {
                id: team_member.id,
                email: team_member.email,
                name: team_member.name,
                tag: team_member.hasOwnProperty("tag")
                  ? team_member.tag
                  : false,
              };
            });

            const company_send_dates = orgData.company_send_dates;

            if (company_send_dates.monday) {
              db.collection("monday")
                .doc(company_id)
                .update({
                  emails: { ...allTeamMembersEmailList },
                });
            }

            if (company_send_dates.tuesday) {
              db.collection("tuesday")
                .doc(company_id)
                .update({
                  emails: { ...allTeamMembersEmailList },
                });
            }

            if (company_send_dates.wednesday) {
              db.collection("wednesday")
                .doc(company_id)
                .update({
                  emails: { ...allTeamMembersEmailList },
                });
            }
            if (company_send_dates.thursday) {
              db.collection("thursday")
                .doc(company_id)
                .update({
                  emails: { ...allTeamMembersEmailList },
                });
            }

            if (company_send_dates.friday) {
              db.collection("friday")
                .doc(company_id)
                .update({
                  emails: { ...allTeamMembersEmailList },
                });
            }

            /**
             *
             * remove invite and write to db
             *
             */
            const invitesList = orgData.invites.filter(
              (invite) => invite.id !== invite_id
            );

            db.collection("company").doc(company_id).update({
              invites: invitesList,
            });

            /**
             *
             * update app state to hold new member info, role
             * update app state to hold general company info from orgData object
             *
             */

            dispatch({
              type: "setCompanyAdminEmail",
              payload: orgData.admin_email,
            });
            dispatch({
              type: "setCompanyAdminId",
              payload: orgData.admin_user_id,
            });

            dispatch({ type: "setCompanyID", payload: orgData.company_id });
            dispatch({ type: "setCompanyName", payload: orgData.company_name });
            dispatch({ type: "setCompanyTags", payload: orgData.company_tags });
            dispatch({
              type: "setCompanyTeamMembers",
              payload: companyTeamMembers,
            });

            dispatch({ type: "setMemberName", payload: name });
            dispatch({ type: "setMemberRole", payload: "EMPLOYEE" });
            dispatch({ type: "setMemberUid", payload: USER_ID });
            dispatch({ type: "setMemberEmail", payload: email });
            dispatch({ type: "setMemberTag", payload: tag });

            /**
             *
             * redirect to company dashboard
             *
             */
            window.location.href = "/dashboard";
          })
          .catch((error) => {
            // failed to update team members list
            var errorMessage = error.message;
            setError(errorMessage);
          });
      })
      .catch((error) => {
        // failed to create user
        var errorMessage = error.message;
        setError(errorMessage);
      });
  };

  return (
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <p>
              <DisplayText size="medium">
                <b>Join {company} on HappyTeam!</b>
              </DisplayText>
              <br />
              <b>
                Hey, <span style={{ textTransform: "capitalize" }}>{name}</span>
                !
              </b>
              <br />
              Your company,{" "}
              <span style={{ textTransform: "capitalize" }}>{company}</span>,
              has invited you to Happy Team – a tool to track your team's mental
              health and well-being.
            </p>
            <br />
            <p>
              <b>How does it work?</b>
            </p>
            <p>
              Throughout the week (on days your company has specified), you'll
              get an email asking how you're doing. You'll be able to respond
              without logging in, can leave a comment to your team's public
              thread, and all of your responses are 100% anonymous.
            </p>
            <br />
            <p>
              By adding your mood, you'll be contributing to the average
              sentiment score of your company, which you'll be able to see via
              your team dashboard.
            </p>
            <br />
            <p>
              Our aim is to give more insight on your company's pulse to better
              help managers, execs, and the rest of team get the most out of
              their work while ensuring mental health and well-being are top of
              mind.
            </p>
            <br />
            <p>
              To get added to {company}'s Happy Team account, finish signup
              below!
            </p>
            <br />
            <FormLayout>
              <TextField
                type="email"
                label="Email"
                value={email}
                requiredIndicator
                disabled
              />

              <TextField
                type="password"
                label="Password"
                onChange={(value) => setPassword(value)}
                value={password}
                requiredIndicator
              />
              <Button primary onClick={handleAccountCreation}>
                Join team {company}
              </Button>

              {error && <div style={{ color: "red" }}>{error}</div>}
            </FormLayout>
          </Card>
        </Layout.Section>
      </Layout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "18px",
        }}
      >
        <img src={WordMark} style={{ height: 22, width: "auto" }} />
      </div>
    </Page>
  );
};

export default JoinCompany;
