import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAKOdKqZSnRXUTXD7ffChAzTZMwp3NIa0A",
  authDomain: "happy-team-de21b.firebaseapp.com",
  projectId: "happy-team-de21b",
  storageBucket: "happy-team-de21b.appspot.com",
  messagingSenderId: "58497328935",
  appId: "1:58497328935:web:7a4eb6ea64f966a5aeab02",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const auth = firebase.auth();

const storage = firebase.storage();

export { auth, db, storage };
